import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createHead1, getCurrentHead1 } from "../../actions/content/head1";

const CreateHead1 = ({
  createHead1,
  getCurrentHead1,
  head1: { head1, loading },
  history
}) => {
  const [formData, setFormData] = useState({
    title: "",
    img: "",
    desc: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  const { title, img, desc } = formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    e.preventDefault();
    createHead1(formData, history);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return loading && head1 === null ? (
    <Redirect to="/dashboard" />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Add a Topic</h1>
      <p className="lead">
        <i class="far fa-plus-square"></i> Let's get some information to create
        a new one
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new Category Title</small>
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder="Image"
            name="img"
            value={img}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">
            Enter the Image Name (Place them in the public/imgs/img folder on
            the Main App)
          </small>
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder="Short Description"
            name="desc"
            value={desc}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the Short Description</small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/head1-page">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

CreateHead1.propTypes = {
  createHead1: PropTypes.func.isRequired,
  getCurrentHead1: PropTypes.func.isRequired,
  head1: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  head1: state.head1
});
export default connect(mapStateToProps, { createHead1, getCurrentHead1 })(
  withRouter(CreateHead1)
);
