import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import UserItem from "./UsersItem";
import { getUsers } from "../../actions/user";
import { Link } from "react-router-dom";

const Users = ({ getUsers, user: { users, loading } }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return loading ? (
    <Spinner />
  ) : (
      <Fragment>
        <h1 className="large text-primary">Users</h1>
        <Link to="/create-user" className="btn btn-primary">
          <i className="fas fa-user-plus"></i> Add a new User
      </Link>
        <div className="posts">
          {users.map(user => (
            <UserItem key={user._id} user={user} />
          ))}
        </div>
      </Fragment>
    );
};

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, { getUsers })(Users);
