import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import SupportItem from "./SupportsItem";
import { getSupports } from "../../actions/support";
import { Link } from "react-router-dom";

const Supports = ({ getSupports, support: { supports, loading }, match }) => {
  useEffect(() => {
    getSupports(match.params.mode);
  }, [getSupports]);

  return loading ? (
    <Spinner />
  ) : (
      <Fragment>
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
        <br />
        {match.params.mode == "1" ? <h1 className="large text-primary">Unresolved Support Queries</h1> : <h1 className="large text-primary">Resolved Support Queries</h1>}
        {supports && supports.length == 0 && (match.params.mode == "1" ? <p>Awesome! You dont have any Unresolved Support Queries!</p> : <p>Awesome! You dont have any Resolved Support Queries!</p>)}
        <div className="posts">
          {supports.map(support => (
            <SupportItem key={support._id} support={support} />
          ))}
        </div>
      </Fragment>
    );
};

Supports.propTypes = {
  getSupports: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  support: PropTypes.object.isRequired

};

const mapStateToProps = state => ({
  user: state.user,
  support: state.support
});

export default connect(mapStateToProps, { getSupports })(Supports);
