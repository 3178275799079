import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createGlobalNotification,
  getCurrentGlobalNotification,
} from "../../actions/globalnotification";
import { setAlert } from "../../actions/alert";
import { Checkbox } from "semantic-ui-react";

const EditGlobalNotification = ({
  globalnotification: { globalnotification, loading },
  createGlobalNotification,
  getCurrentGlobalNotification,
  history,
  match,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    text: "",
    _id: null,
    plansList: ["plan1", "plan2", "plan3", "plan4", "plan5", "plan6", "plan7"],
    listOfSelectedPlans: [false, false, false, false, false, false, false],
  });

  useEffect(() => {
    console.log("useEffect");
    getCurrentGlobalNotification(match.params.id);

    setFormData({
      text: !globalnotification ? "" : globalnotification.text,
      _id: !globalnotification ? null : globalnotification._id,
    });
  }, [loading, getCurrentGlobalNotification, match]);

  const { text, plansList, listOfSelectedPlans, _id } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    createGlobalNotification(formData, history);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit the Global Notification</h1>
      <p className="lead">
        <i className="fas fa-globalnotification" /> Let's get some information
        to edit the Global Notification
      </p>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Text"
            name="text"
            value={text}
            onChange={(e) => onChange(e)}
            isRequired
          />
          <small className="form-text">
            Enter the new Global Notification's Text
          </small>
        </div>

        {plansList && plansList.length > 0 ? (
          plansList.map((plan, id) => (
            <p>
              <Checkbox
                onChange
                label={"   " + plan}
                checked={listOfSelectedPlans[id]}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                onChange={(e, { checked }) => {
                  // eslint-disable-next-line no-lone-blocks
                  {
                    var tempPlansList = listOfSelectedPlans;
                    tempPlansList[id] = checked;

                    setFormData({
                      ...formData,
                      listOfSelectedPlans: tempPlansList,
                    });
                  }
                }}
              />{" "}
            </p>
          ))
        ) : (
          <p>This group has access to all the subtopics</p>
        )}

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/globalnotifications">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditGlobalNotification.propTypes = {
  createGlobalNotification: PropTypes.func.isRequired,
  getCurrentGlobalNotification: PropTypes.func.isRequired,
  globalnotification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  globalnotification: state.globalnotification,
});

export default connect(mapStateToProps, {
  setAlert,
  createGlobalNotification,
  getCurrentGlobalNotification,
})(withRouter(EditGlobalNotification));
