import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getSourceUserInfo, getDestUserInfo, clearMigratePage, startMigration } from "../../actions/user";
import { Link } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import Parser from 'html-react-parser';

const MigrateUsers = ({ 
  getSourceUserInfo, 
  getDestUserInfo, 
  clearMigratePage, 
  startMigration, 
  setAlert, 
  user: { 
    sUserInfo, 
    dUserInfo,
    migration 
  } 
}
) => {
  useEffect(() => {
    clearMigratePage();
  }, [clearMigratePage]);

  const [formData, setFormData] = useState({
    sEmailID: "",
    dEmailID: "",
  });

  const { sEmailID, dEmailID } = formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmitS = e => {
    e.preventDefault();
    getSourceUserInfo(sEmailID);
  };

  const onSubmitD = e => {
    e.preventDefault();
    getDestUserInfo(dEmailID);
  };

  return (
    <Fragment>
      <Link className="btn btn-light my-1" to="/dashboard">
        Go Back
        </Link>

      <h1 className="large text-primary">Migrate Users</h1>
      

      <h2 className="text-primary">Source User</h2>
      <form className="form" onSubmit={e => onSubmitS(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Source User Email ID"
            name="sEmailID"
            value={sEmailID}
            onChange={e => onChange(e)}
          />
          <small className="form-text">Enter the Email ID of the user that needs to be migrated</small>
        </div>

        <input style={{ marginTop: "0px", marginBottom: "0px" }} type="submit" value="Fetch Source User Info" className="btn btn-primary my-1" />
      </form>
      <br />
      <div>
        {sUserInfo ? Parser(sUserInfo) : <div></div>}
      </div>
<br/>
      <h2 className="text-primary">Destination User</h2>
      <form className="form" onSubmit={e => onSubmitD(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Destination User Email ID"
            name="dEmailID"
            value={dEmailID}
            onChange={e => onChange(e)}
          />
          <small className="form-text">Enter the Email ID of the user to which the source user progresses have top be migrated</small>
        </div>

        <input style={{ marginTop: "0px", marginBottom: "0px" }} type="submit" value="Fetch Destination User Info" className="btn btn-primary my-1" />
      </form>
      <br />
      <div>
        {dUserInfo ? Parser(dUserInfo) : <div></div>}
      </div>

      <br/>
      {(migration == 1) ? <h2>User Migration Process in Progress...</h2> : <p></p>}

      {(migration == 2) ? <h2>User Migration has been successfully done</h2> : <p></p>}

      {(migration == 3) ? <h2>User Migration has failed</h2> : <p></p>}
      <br/>
      <p>
      <button 
        type="button"
        className="btn btn-success"
        onClick={() => {
          startMigration(sEmailID, dEmailID);
         
        }}
      >     
      
        Migrate User ***CAUTION***
    </button>
    </p>


    <br/>
    <br/>

    <img alt="migration img" className="center" src={"migrationImg.png"} />
    </Fragment>
  );
};

MigrateUsers.propTypes = {
  getSourceUserInfo: PropTypes.func.isRequired,
  getDestUserInfo: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  clearMigratePage: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  startMigration: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, { 
  getSourceUserInfo,
  getDestUserInfo, 
  clearMigratePage,
  startMigration,
  setAlert
 })(MigrateUsers);
