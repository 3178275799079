import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { markAsDone, deleteComment } from "../../actions/comment";
const uuid = require("uuid");

const ShowCommentItem = ({
  markAsDone,
  auth,
  comment: { id, by, byUserId, comment, at, assetType, comments, date, served },
  assetId,
  showActions,
  deleteComment
}) => {

  const [open, setOpen] = React.useState(false)

  var regularClassName = "useritem bg-white p-1 my-1";
  if (byUserId == "bytsindia") {
    regularClassName = "useritem bg-green p-1 my-1";
  }
  return <div className={regularClassName}>

    <div>

      <h4>{by}</h4>

      <h4>{byUserId}</h4>


    </div>
    <div>
      <h4>{comment}</h4>

      <p className="post-date">
        {at ? <Moment fromNow date={at} /> : <Fragment></Fragment>}
        <br />
        {at}

      </p>
    </div>
    <Fragment>

      <p>
        <a
          className="btn btn-danger"
          onClick={() => {
            deleteComment({ id: id, assetId: assetId })
          }}
        >
          Delete
          </a>
      </p>
    </Fragment>
  </div >


};

ShowCommentItem.defaultProps = {
  showActions: true
};

ShowCommentItem.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  markAsDone: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { markAsDone, deleteComment })(ShowCommentItem);
