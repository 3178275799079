import axios from "axios";
import { BASE_SERVER_URL } from "../../defaults";

import { setAlert } from "../alert.js";
import { GET_HEAD2S, GET_HEAD2, HEAD2_ERROR, DELETE_HEAD2 } from "../types";

// Create or update user
export const createHead2 = (
  formData,
  history,
  edit = false,
  id = null
) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/content/addhead2",
      { ...formData, id },
      config
    );

    dispatch({
      type: GET_HEAD2,
      payload: res.data
    });

    dispatch(
      setAlert(
        edit
          ? "The Subtopic details have been updated successfully"
          : "Subtopic was created successfully",
        "success"
      )
    );

    history.push("/head2-page");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: HEAD2_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get posts
export const getHead2s = () => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/content/head2");

    dispatch({
      type: GET_HEAD2S,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HEAD2_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get current user
export const getCurrentHead2 = id => async dispatch => {
  try {
    const res = await axios.get(`/api/content/head2/${id}`);

    dispatch({
      type: GET_HEAD2,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HEAD2_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete post
export const deleteHead2 = id => async dispatch => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await axios.delete(`/api/content/head2/${id}`);
      dispatch({
        type: DELETE_HEAD2,
        payload: id
      });

      dispatch(setAlert("The Topic has been removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: HEAD2_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
