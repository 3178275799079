import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import {
  GET_GLOBALNOTIFICATIONS,
  GET_GLOBALNOTIFICATION,
  GLOBALNOTIFICATION_ERROR,
  UPDATE_GLOBALNOTIFICATION,
  DELETE_GLOBALNOTIFICATION,
  ADD_GLOBALNOTIFICATION,
  DEPLOY_GLOBALNOTIFICATIONS,
} from "./types";

// Get current user
export const getCurrentGlobalNotification = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/globalnotifications/globalnotification/${id}`
    );

    dispatch({
      type: GET_GLOBALNOTIFICATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GLOBALNOTIFICATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create or update user
export const createGlobalNotification = (
  formData,
  history,
  edit = false,
  id = null
) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    var listOfPlans = [];
    if (formData) {
      if (formData.plansList && formData.listOfSelectedPlans) {
        for (var idx in formData.plansList) {
          var planId = formData.plansList[idx].split(" ")[0];

          if (formData.listOfSelectedPlans[idx] == true) {
            listOfPlans.push(planId);
          }
        }
      }
    }

    const res = await axios.post(
      "/api/globalnotifications/addglobalnotification",
      { text: formData.text, plans: listOfPlans, id },
      config
    );

    dispatch({
      type: GET_GLOBALNOTIFICATION,
      payload: res.data,
    });

    dispatch(
      setAlert(
        formData._id
          ? "The Global Notification details have been updated successfully"
          : "The Global Notification was created successfully",
        "success"
      )
    );

    history.push("/globalnotifications");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch(setAlert("The Global Notification couldn't be added!", "danger"));

    dispatch({
      type: GLOBALNOTIFICATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get posts
export const getGlobalNotifications = () => async (dispatch) => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/globalnotifications");

    dispatch({
      type: GET_GLOBALNOTIFICATIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GLOBALNOTIFICATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deployGlobalNotifications = () => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      const res = await axios.get(
        BASE_SERVER_URL + "/api/globalnotifications/deploy"
      );

      if (res.data["msg"]) {
        dispatch(setAlert(res.data["msg"], "danger"));
      } else {
        dispatch(
          setAlert(
            "The Global Notification has been pushed to PRODUCTION successfully",
            "success"
          )
        );
      }

      dispatch({
        type: DEPLOY_GLOBALNOTIFICATIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);

      dispatch(
        setAlert(
          "Unable to push the Global Notification to PRODUCTION!",
          "danger"
        )
      );

      dispatch({
        type: GLOBALNOTIFICATION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

// Delete post
export const deleteGlobalNotification = (id) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await axios.delete(`/api/globalnotifications/${id}`);
      dispatch({
        type: DELETE_GLOBALNOTIFICATION,
        payload: id,
      });

      dispatch(setAlert("Global Notification has been removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: GLOBALNOTIFICATION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};
