import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deleteHead2 } from "../../actions/content/head2";

const Head2Item = ({
  deleteHead2,
  auth,
  head2: { _id, title },
  showActions
}) => (
  <div className="post bg-white p-1 my-1">
    <h2>{title}</h2>

    {showActions && (
      <Fragment>
        <div className="aParent">
          <p>
            <Link to={`/edit-head2/${_id}`} className="btn btn-primary">
              Edit Subtopic
            </Link>
          </p>
          <br />

          <p>
            <button
              onClick={() => deleteHead2(_id)}
              type="button"
              className="btn btn-danger"
            >
              Delete Subtopic
            </button>
          </p>
        </div>
      </Fragment>
    )}
  </div>
);

Head2Item.defaultProps = {
  showActions: true
};

Head2Item.propTypes = {
  head2: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  content: state.content
});

export default connect(mapStateToProps, { deleteHead2 })(Head2Item);
