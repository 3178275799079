import React from "react";
import { Route, Switch } from "react-router-dom";
import Register from "../auth/Register";
import Login from "../auth/Login";
import Alert from "../layout/Alert";
import Dashboard from "../dashboard/Dashboard";

import CreateUser from "../user-forms/CreateUser";
import EditUser from "../user-forms/EditUser";
import Content from "../content/Content";
import Head1 from "../head1/Head1s";
import CreateHead1 from "../head1-forms/CreateHead1";
import EditHead1 from "../head1-forms/EditHead1";
import Head2 from "../head2/Head2s";
import CreateHead2 from "../head2-forms/CreateHead2";
import EditHead2 from "../head2-forms/EditHead2";
import Asset from "../asset/Assets";
import CreateAsset from "../asset-forms/CreateAsset";
import EditAsset from "../asset-forms/EditAsset";

import UploadPage from "../deploy/UploadPage";
import Deploy from "../deploy/Deploy";
import AddDeploy from "../deploy-forms/AddDeploy";

import CreateProfile from "../profile-forms/CreateProfile";
import EditProfile from "../profile-forms/EditProfile";
import AddExperience from "../profile-forms/AddExperience";
import AddEducation from "../profile-forms/AddEducation";
import Profiles from "../profiles/Profiles";
import Profile from "../profile/Profile";
import Posts from "../posts/Posts";
import Post from "../post/Post";
import Users from "../users/Users";
import User from "../users/Users";
import NotFound from "../layout/NotFound";
import PrivateRoute from "../routing/PrivateRoute";

import PricingPlans from "../pricingplans/PricingPlans";
import CreatePricingPlan from "../pricingplan-forms/CreatePricingPlan";
import EditPricingPlan from "../pricingplan-forms/EditPricingPlan";
import DeployPricingPlans from "../pricingplans/DeployPricingPlans";

import GlobalNotifications from "../globalnotifications/GlobalNotifications";
import CreateGlobalNotification from "../globalnotification-forms/CreateGlobalNotification";
import EditGlobalNotification from "../globalnotification-forms/EditGlobalNotification";

import Supports from "../support/Supports";
import Comments from "../comment/Comments";
import ShowComments from "../comment/ShowComments";

import OfflineUsersUploadPage from "../offlineusers/UploadPage";

import Groups from "../stagedrollout/Groups";
import EditGroup from "../stagedrollout-forms/EditGroup";

import ConsolidatedReport from "../analytics/ConsolidatedReport";

import MigrateUser from "../users/MigrateUsers";

const Routes = () => {
  return (
    <section className="container">
      <Alert />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/profiles" component={Profiles} />
        <Route exact path="/profile/:id" component={Profile} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />

        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/users/:id" component={User} />
        <PrivateRoute exact path="/create-user" component={CreateUser} />
        <PrivateRoute exact path="/edit-user/:id" component={EditUser} />

        <PrivateRoute exact path="/migrateusers" component={MigrateUser} />

        <PrivateRoute exact path="/pricingplans" component={PricingPlans} />
        <PrivateRoute
          exact
          path="/create-pricingplan"
          component={CreatePricingPlan}
        />
        <PrivateRoute
          exact
          path="/edit-pricingplan/:id"
          component={EditPricingPlan}
        />
        <PrivateRoute
          exact
          path="/deploypricingplans"
          component={DeployPricingPlans}
        />

        <PrivateRoute
          exact
          path="/globalnotifications"
          component={GlobalNotifications}
        />
        <PrivateRoute
          exact
          path="/create-globalnotification"
          component={CreateGlobalNotification}
        />
        <PrivateRoute
          exact
          path="/edit-globalnotification/:id"
          component={EditGlobalNotification}
        />

        <PrivateRoute exact path="/content-page" component={Content} />
        <PrivateRoute exact path="/head1-page" component={Head1} />
        <PrivateRoute exact path="/create-head1" component={CreateHead1} />
        <PrivateRoute exact path="/edit-head1/:id" component={EditHead1} />
        <PrivateRoute exact path="/head2-page" component={Head2} />
        <PrivateRoute exact path="/create-head2" component={CreateHead2} />
        <PrivateRoute exact path="/edit-head2/:id" component={EditHead2} />
        <PrivateRoute exact path="/asset-page" component={Asset} />
        <PrivateRoute exact path="/create-asset" component={CreateAsset} />
        <PrivateRoute exact path="/edit-asset/:id" component={EditAsset} />

        <PrivateRoute exact path="/deploy" component={Deploy} />
        <PrivateRoute exact path="/upload" component={UploadPage} />
        <PrivateRoute
          exact
          path="/uploadofflineusers"
          component={OfflineUsersUploadPage}
        />

        <PrivateRoute exact path="/offlineusersgroup" component={Groups} />
        <PrivateRoute exact path="/edit-group/:id" component={EditGroup} />

        <PrivateRoute
          exact
          path="/add-deploy/:id/:index"
          component={AddDeploy}
        />
        <PrivateRoute
          exact
          path="/add-deploy/:topicid/:id/:index"
          component={AddDeploy}
        />

        <PrivateRoute exact path="/create-profile" component={CreateProfile} />
        <PrivateRoute exact path="/edit-profile" component={EditProfile} />
        <PrivateRoute exact path="/add-experience" component={AddExperience} />
        <PrivateRoute exact path="/add-education" component={AddEducation} />
        <PrivateRoute exact path="/posts" component={Posts} />
        <PrivateRoute exact path="/posts/:id" component={Post} />

        <PrivateRoute exact path="/support/:mode" component={Supports} />
        <PrivateRoute exact path="/comment/:mode" component={Comments} />
        <PrivateRoute
          exact
          path="/showcomments/:mode/:assetId"
          component={ShowComments}
        />

        <PrivateRoute
          exact
          path="/consolidatedreport"
          component={ConsolidatedReport}
        />

        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
