import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUser, getCurrentUser } from "../../actions/user";
import { setAlert } from "../../actions/alert";

const EditUser = ({
  user: { user, loading },
  createUser,
  getCurrentUser,
  history,
  match,
  setAlert
}) => {
  const [formData, setFormData] = useState({
    priviledge: "",
    name: "",
    email: "",
    password: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    console.log("useEffect");
    getCurrentUser(match.params.id);

    setFormData({
      priviledge: !user ? "" : user.priviledge,
      name: !user ? "" : user.name,
      email: !user ? "" : user.email
    });

    if (user && user.name) console.log("User: " + formData.name);
  }, [loading, getCurrentUser, match]);

  const { name, email, priviledge, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (displaySocialInputs && password && password.length >= 6) {
      createUser(formData, history, true, user._id);
    } else if (!displaySocialInputs) {
      createUser(formData, history, true, user._id);
    } else {
      setAlert("Password should have a minimum of 6 characters", "danger");
    }
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit this User Profile</h1>
      <p className="lead">
        <i className="fas fa-user" /> Let's get some updated information to edit
        this Profile
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <select
            name="priviledge"
            value={priviledge}
            onChange={e => onChange(e)}
          >
            <option value="0">* Select User Priviledge</option>
            <option value="Master Administrator">Master Administrator</option>
            <option value="Content Creator">Content Creator</option>
          </select>
          <small className="form-text">
            Select the level of Priviledge that needs to be associated with this
            new account
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={e => onChange(e)}
          />
          <small className="form-text">Enter the new User's name</small>
        </div>
        <div className="form-group">
          <small className="form-text">
            The email id mapped to this user profile
          </small>
          <p className="lead">{email}</p>
        </div>

        <div className="my-2">
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type="button"
            className="btn btn-primary"
          >
            Change Password
          </button>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div className="form-group">
              <input
                type="text"
                placeholder="Password"
                name="password"
                value={password}
                onChange={e => onChange(e)}
              />
              <small className="form-text">
                Need a minimum of 6 characters
              </small>
            </div>
          </Fragment>
        )}

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/users">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditUser.propTypes = {
  createUser: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, {
  setAlert,
  createUser,
  getCurrentUser
})(withRouter(EditUser));
