import {
  GET_SUPPORTS,
  GET_SUPPORT,
  SUPPORT_ERROR,
  UPDATE_SUPPORT,
  DELETE_SUPPORT
} from "../actions/types";

const initialState = {
  support: null,
  supports: [],
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SUPPORT:

    case GET_SUPPORTS:
      return {
        ...state,
        supports: payload,
        loading: false
      };



    /*
  case DELETE_USER:
    return {
      ...state,
      users: state.users.filter(user => user._id !== payload),
      loading: false
    };
    */
    case SUPPORT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
