import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import {
  GET_DEPLOY_SCHEME,
  UPDATE_DEPLOY_SCHEME,
  DEPLOY_SCHEME_ERROR,
  GET_DEVDEPLOY_SCHEME,
  UPDATE_DEVDEPLOY_SCHEME,
  DELETE_DEPLOY_ITEM,
  PUSH_TO_PROD
} from "./types";

// Create or update user
export const updateDevDeployScheme = (
  formData,
  history,
  id,
  index,
  topicid = "head",
  edit = false
) => async dispatch => {
  console.log("Calling with formData: " + formData);
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      `/api/deploy/deployadd/${topicid}/${id}/${index}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_DEPLOY_SCHEME,
      payload: res.data
    });

    dispatch(
      setAlert(
        edit
          ? "The Deploy Schema have been updated successfully"
          : "The Deploy Schema was created successfully",
        "success"
      )
    );

    history.push("/deploy");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: DEPLOY_SCHEME_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get posts
export const pushDevToProd = () => async dispatch => {
  if (
    window.confirm(
      "You are about to push the DEV SCHEMA to the PRODUCTION SCHEMA. Are you sure? This CANNOT be undone!"
    )
  ) {
    try {
      const res = await axios.get(
        BASE_SERVER_URL + "/api/deploy/pushdevtoprod"
      );

      dispatch({
        type: PUSH_TO_PROD,
        payload: res.data
      });

      if (res && res.data && res.data.errors) {
        res.data.errors.forEach(error => dispatch(setAlert(error, "danger")));
      }

      dispatch(
        setAlert(
          "SCHEMA has been pushed successfully to PRODUCTION!",
          "success"
        )
      );
    } catch (err) {
      dispatch({
        type: DEPLOY_SCHEME_ERROR,
        payload: {
          msg: err.response ? err.response.statusText : "Deploy Schema Error",
          status: err.response ? err.response.status : "500"
        }
      });
    }
  }
};

// Get posts
export const getAllDeployScheme = () => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/deploy/all");

    dispatch({
      type: GET_DEVDEPLOY_SCHEME,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DEPLOY_SCHEME_ERROR,
      payload: {
        msg: err.response ? err.response.statusText : "Deploy Schema Error",
        status: err.response ? err.response.status : "500"
      }
    });
  }
};

// Delete post
export const deleteDeployDevItem = id => async dispatch => {
  if (window.confirm("Are you sure? This can NOT be undone! ")) {
    try {
      const res = await axios.delete(`/api/deploy/item/${id}`);
      dispatch({
        type: DELETE_DEPLOY_ITEM,
        payload: res.data
      });

      dispatch(setAlert("The Deploy Item has been removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: DEPLOY_SCHEME_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
