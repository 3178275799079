import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getContent } from "../../actions/content";
import { getHead1s } from "../../actions/content/head1";

import { Link } from "react-router-dom";

const Content = ({ getContent, content: { content, loading } }) => {
  useEffect(() => {
    getContent();
    getHead1s();
  }, [getContent, getHead1s, loading]);

  function ShowHead1s() {
    if (content && content.listOfHead1s)
      return (
        <Fragment>
          <h1 className="large-nobottom text-primary">Topics</h1>

          {content &&
            content.listOfHead1s.map(head1 => {
              return <p className="posts">{head1.title}</p>;
            })}
        </Fragment>
      );
    else return <h1 className="large text-primary">No Topics added so far.</h1>;
  }

  function ShowHead2s() {
    if (content && content.listOfHead2s)
      return (
        <Fragment>
          <h1 className="large-nobottom text-primary">Subtopics</h1>

          {content &&
            content.listOfHead2s.map(head2 => {
              return <p className="posts">{head2.title}</p>;
            })}
        </Fragment>
      );
    else
      return <h1 className="large text-primary">No Subtopics added so far.</h1>;
  }

  function ShowAssets() {
    if (content && content.listOfAssets)
      return (
        <Fragment>
          <h1 className="large-nobottom text-primary">Assets</h1>

          {content &&
            content.listOfAssets.map(asset => {
              return (
                <p className="posts">
                  ({asset.type}) {asset.ref}
                </p>
              );
            })}
        </Fragment>
      );
    else return <h1 className="large text-primary">No Asset added so far.</h1>;
  }

  return loading ? (
    <Spinner />
  ) : (
      <Fragment>
        <h1 className="large text-primary">Content Management</h1>
        <div className="dash-buttons">
          <Link to="/head1-page" className="btn btn-primary">
            <i class="far fa-edit"></i> Manage Topics
        </Link>
          <Link to="/head2-page" className="btn btn-primary">
            <i class="far fa-edit"></i> Manage Subtopics
        </Link>
          <Link to="/asset-page" className="btn btn-primary">
            <i class="far fa-edit"></i> Manage Assets
        </Link>
        </div>
        <br />
        <ShowHead1s />
        <br />
        <ShowHead2s />
        <br />
        <ShowAssets />
        <br />
      </Fragment>
    );
};

Content.propTypes = {
  getContent: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  content: state.content
});

export default connect(mapStateToProps, { getContent })(Content);
