import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createHead1, getCurrentHead1 } from "../../actions/content/head1";

const EditHead1 = ({
  head1: { head1, loading },
  createHead1,
  getCurrentHead1,
  history,
  match
}) => {
  const [formData, setFormData] = useState({
    title: "",
    img: "",
    desc: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    console.log("useEffect");
    getCurrentHead1(match.params.id);

    setFormData({
      title: !head1 ? "" : head1.title,
      img: !head1 ? "" : head1.img,
      desc: !head1 ? "" : head1.desc
    });
  }, [loading, getCurrentHead1, match]);

  const { title, img, desc } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    createHead1(formData, history, true, head1._id);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit the Topic</h1>
      <p className="lead">
        <i class="far fa-plus-square"></i> Let's get some information to modify
        this
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new Category Title</small>
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder="Image"
            name="img"
            value={img}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">
            Enter the Image Name (Place them in the public/imgs/img folder on
            the Main App)
          </small>
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder="Short Description"
            name="desc"
            value={desc}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the Short Description</small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/head1-page">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditHead1.propTypes = {
  createHead1: PropTypes.func.isRequired,
  getCurrentHead1: PropTypes.func.isRequired,
  head1: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  head1: state.head1
});

export default connect(mapStateToProps, { createHead1, getCurrentHead1 })(
  withRouter(EditHead1)
);
