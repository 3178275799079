import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deleteUser } from "../../actions/user";

const UserItem = ({
  deleteUser,
  auth,
  user: { _id, name, avatar, email, date, priviledge },
  showActions
}) => (
  <div className="useritem bg-white p-1 my-1">
    <div>
      <Link to={`#!`}>
        <img className="round-img" src={avatar} alt="" />
        <h4>{name}</h4>
        <br />
      </Link>
    </div>
    <div>
      <p className="lead">{priviledge}</p>
      <p className="my-1">{email}</p>
      <p className="post-date">
        Created on <Moment format="YYYY/MM/DD">{date}</Moment>
      </p>
    </div>
    {showActions && (
      <Fragment>
        <p>
          <Link to={`/edit-user/${_id}`} className="btn btn-primary">
            Edit User Account
          </Link>
        </p>
        <br />
        {!auth.loading && _id !== auth.user._id && (
          <p>
            <button
              onClick={() => deleteUser(_id)}
              type="button"
              className="btn btn-danger"
            >
              Delete User Account
            </button>
          </p>
        )}
      </Fragment>
    )}
  </div>
);

UserItem.defaultProps = {
  showActions: true
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deleteUser })(UserItem);
