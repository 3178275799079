import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createHead2, getCurrentHead2 } from "../../actions/content/head2";

const EditHead2 = ({
  head2: { head2, loading },

  createHead2,
  getCurrentHead2,
  history,
  match
}) => {
  const [formData, setFormData] = useState({
    title: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    console.log("useEffect");
    getCurrentHead2(match.params.id);

    setFormData({
      title: !head2 ? "" : head2.title
    });
  }, [loading, getCurrentHead2, match]);

  const { title } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    createHead2(formData, history, true, head2._id);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit the Subtopic</h1>
      <p className="lead">
        <i class="far fa-plus-square"></i> Let's get some information to modify
        this
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new Category Title</small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/head2-page">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditHead2.propTypes = {
  createHead2: PropTypes.func.isRequired,
  getCurrentHead2: PropTypes.func.isRequired,
  head2: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  head2: state.head2
});

export default connect(mapStateToProps, {
  createHead2,
  getCurrentHead2
})(withRouter(EditHead2));
