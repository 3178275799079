import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createAsset, getCurrentAsset } from "../../actions/content/asset";
import Spinner from "../layout/Spinner";

const EditAsset = ({
  asset: { asset, loading },
  createAsset,
  getCurrentAsset,
  history,
  match
}) => {
  const [formData, setFormData] = useState({
    type: "",
    index: "",
    ref: "",
    qn: "",
    chC: "",
    chW: "",

    problem: "",
    tc: "",
    sin: "",
    sout: "",
    hint1: "",
    hint2: "",
    hintVideo: "",
    uuid: "",
    title: "",
    url: "",
    desc: "",
    text: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  const [display, changeDisplay] = useState({
    toShow: 0
  });

  useEffect(() => {
    console.log("useEffect");
    getCurrentAsset(match.params.id);

    var toShow = 0;
    if (asset) {
      if (asset.type == "video") {
        toShow = 0;
      } else if (asset.type == "text") {
        toShow = 2;
      } else if (asset.type == "problem") {
        toShow = 1;
      } else if (asset.type == "mcq") {
        toShow = 3;
      }
    }

    console.log("toShow: " + toShow.toString());
    setFormData({
      title: !asset ? "" : asset.title,
      type: !asset ? "" : asset.type,
      index: !asset ? "" : asset.index,
      ref: !asset ? "" : asset.ref,
      qn: !asset ? "" : asset.qn,
      chC: !asset ? "" : asset.chC,
      chW: !asset ? "" : asset.chW,

      problem: !asset ? "" : asset.problem,
      difficulty: !asset ? "" : asset.difficulty,
      tags: !asset ? "" : asset.tags,
      feed: !asset ? false : asset.feed,
      tc: !asset ? "" : asset.tc,
      sin: !asset ? "" : asset.sin,
      sout: !asset ? "" : asset.sout,
      hint1: !asset ? "" : asset.hint1,
      hint2: !asset ? "" : asset.hint2,
      hintVideo: !asset ? "" : asset.hintVideo,
      uuid: !asset ? "" : asset.uuid,
      title: !asset ? "" : asset.title,
      url: !asset ? "" : asset.url,
      desc: !asset ? "" : asset.desc,
      text: !asset ? "" : asset.text,
      toShow: toShow
    });
    changeDisplay({ toShow });
  }, [loading, getCurrentAsset, match]);

  const {
    type,
    index,
    ref,
    qn,
    chC,
    chW,

    problem,
    difficulty,
    tags,
    feed,
    tc,
    sin,
    sout,
    hint1,
    hint2,
    hintVideo,
    uuid,
    title,
    url,
    desc,
    text
  } = formData;

  const listOfTypes = ["video", "problem", "text", "mcq"];

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onValueChange = e => {
    changeDisplay({ toShow: e.target.value });
  };
  const onSubmit = e => {
    e.preventDefault();
    // add the type to the formData
    const newFormData = { ...formData, type: listOfTypes[display.toShow] };
    createAsset(formData, history, true, asset._id);
  };

  const onChangeFeedYes = e => {
    setFormData({ ...formData, feed: true });
  };

  const onChangeFeedNo = e => {
    setFormData({ ...formData, feed: false });
  };


  return (
    <Fragment>
      <h1 className="large text-primary">Edit an Asset</h1>

      <p className="lead">
        <i class="far fa-plus-square"></i> Let's get some information to create
        a new one
      </p>

      <div className="form">
        <select
          name="toShow"
          value={display.toShow}
          onChange={e => onValueChange(e)}
        >
          <option value={0}>Video</option>
          <option value={1}>Problem</option>
          <option value={2}>Text</option>
          <option value={3}>MCQ</option>
        </select>
        <small className="form-text">Select the Asset Type</small>
      </div>
      {(() => {
        if (display.toShow == 0) {
          // render video component
          return (
            <form className="form" onSubmit={e => onSubmit(e)}>
              <p className="form">Showing elements for VIDEO Asset</p>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={title}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Video Title (This will be shown in the Sidebar after
                  Subtopic Selection)
                </small>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="URL"
                  name="url"
                  value={url}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">Enter the Video URL</small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Description"
                  name="desc"
                  value={desc}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">Enter the Video Description</small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Reference Title"
                  name="ref"
                  value={ref}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Reference Title to identify this asset
                </small>
              </div>

              <input type="submit" className="btn btn-primary my-1" />
              <Link className="btn btn-light my-1" to="/asset-page">
                Go Back
              </Link>
            </form>
          );
        } else if (display.toShow == 2) {
          // render video component
          return (
            <form className="form" onSubmit={e => onSubmit(e)}>
              <p className="form">Showing elements for TEXT Asset</p>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={title}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Text Content Title (This will be shown in the
                  Sidebar after Subtopic Selection)
                </small>
              </div>
              <div className="form-group">
                <textarea
                  rows="50"
                  cols="60"
                  placeholder="Text"
                  name="text"
                  value={text}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Textual Content here (HTML Tags can be used to
                  format the text)
                </small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Reference Title"
                  name="ref"
                  value={ref}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Reference Title to identify this asset
                </small>
              </div>

              <input type="submit" className="btn btn-primary my-1" />
              <Link className="btn btn-light my-1" to="/asset-page">
                Go Back
              </Link>
            </form>
          );
        } else if (display.toShow == 1) {
          // render video component
          return (
            <form className="form" onSubmit={e => onSubmit(e)}>
              <p className="form">Showing elements for PROBLEM Asset</p>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={title}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Problem Title (This will be shown in the Sidebar
                  after Subtopic Selection)
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Description"
                  name="desc"
                  value={desc}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Description here (Will be shown on the
                  AssetPane)
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Problem"
                  name="problem"
                  value={problem}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Question here
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Difficulty Rating"
                  name="difficulty"
                  value={difficulty}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Difficulty Rating here (numbered 1 to ...)
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Tags (Comma Sepated List)"
                  name="tags"
                  value={tags}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's associated Tags here (Comma Sepated List)
                </small>
              </div>

              <div className="form-group">
                <input type="radio" id="feed" name="feed" checked={feed} onChange={e => onChangeFeedYes(e)}>
                </input>
                <label for="feed">  YES</label><br />

                <input type="radio" id="feed" name="feed" checked={!feed} onChange={e => onChangeFeedNo(e)}>
                </input>
                <label for="feed">  NO</label><br />
                <small className="form-text">
                  Should this problem be shown on the Problem Feed?
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="TestCase"
                  name="tc"
                  value={tc}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Test Case here
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Sample Input"
                  name="sin"
                  value={sin}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Sample Input here
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Sample Output"
                  name="sout"
                  value={sout}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Sample Output here
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Hint"
                  name="hint1"
                  value={hint1}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Hint 1 here
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Hint 2"
                  name="hint2"
                  value={hint2}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Hint 2 here
                </small>
              </div>

              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Hint Video URL"
                  name="hintVideo"
                  value={hintVideo}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">
                  Enter the Problem's Hint Video URL here
                </small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Reference UUID"
                  name="uuid"
                  value={uuid}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the new Ref. UUID (Used by the Backend here)
                </small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Reference Title"
                  name="ref"
                  value={ref}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Reference Title to identify this asset
                </small>
              </div>

              <input type="submit" className="btn btn-primary my-1" />
              <Link className="btn btn-light my-1" to="/asset-page">
                Go Back
              </Link>
            </form>
          );
        } else if (display.toShow == 3) {
          // render video component
          return (
            <form className="form" onSubmit={e => onSubmit(e)}>
              <p className="form">Showing elements for MCQ Asset</p>
              <div className="form-group">
                <small className="form-text">
                  MCQs will be collectively shown for a subtopic at the end so
                  we dont need a Text Title to show on the Sidebar
                </small>
              </div>
              <div className="form-group">
                <textarea
                  rows="5"
                  cols="60"
                  placeholder="Question"
                  name="qn"
                  value={qn}
                  onChange={e => onChange(e)}
                ></textarea>
                <small className="form-text">Enter the MCQ Question here</small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Choice 1"
                  name="chC"
                  value={chC}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the CORRECT Choices here (Comma separated List)
                </small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Choice 2"
                  name="chW"
                  value={chW}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  nter the WRONG Choices here (Comma separated List)
                </small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Reference Title"
                  name="ref"
                  value={ref}
                  onChange={e => onChange(e)}
                  isRequired
                />
                <small className="form-text">
                  Enter the Reference Title to identify this asset
                </small>
              </div>

              <input type="submit" className="btn btn-primary my-1" />
              <Link className="btn btn-light my-1" to="/asset-page">
                Go Back
              </Link>
            </form>
          );
        }
      })()}
      <div>{() => { }}</div>
    </Fragment>
  );
};

EditAsset.propTypes = {
  createAsset: PropTypes.func.isRequired,
  getCurrentAsset: PropTypes.func.isRequired,
  asset: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  asset: state.asset,
  content: state.content
});

export default connect(mapStateToProps, {
  createAsset,
  getCurrentAsset
})(withRouter(EditAsset));
