import {
  GET_PRICINGPLANS,
  GET_PRICINGPLAN,
  PRICINGPLAN_ERROR,
  UPDATE_PRICINGPLAN,
  DELETE_PRICINGPLAN,
  DEPLOY_PRICINGPLANS
} from "../actions/types";

const initialState = {
  pricingplan: null,
  pricingplans: [],
  loading: true,
  deployPricingPlan: {},
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRICINGPLANS:
      return {
        ...state,
        pricingplans: payload,
        loading: false
      };
    case GET_PRICINGPLAN:
    case UPDATE_PRICINGPLAN:
      return {
        ...state,
        pricingplan: payload,
        loading: true // TODO: Check this once. true enables proper working of the edit-pricingplan page. HOW is this possible?
      };
    case DELETE_PRICINGPLAN:
      return {
        ...state,
        pricingplans: { ...state.pricingplans, plans: state.pricingplans.plans.filter(pricingplan => pricingplan._id !== payload) },
        loading: false
      };
    case PRICINGPLAN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case DEPLOY_PRICINGPLANS:
      return {
        ...state,
        deployPricingPlan: payload
      }
    default:
      return state;
  }
}
