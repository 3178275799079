import {
  ERROR_CREPORT,
  GET_CREPORT
} from "../actions/types";

const initialState = {
  analytics: null,
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CREPORT:
      return {
        ...state,
        analytics: { cReport: payload },
        loading: false
      };

    case ERROR_CREPORT:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
