import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import user from "./user";
import content from "./content";
import head1 from "./content/head1";
import head2 from "./content/head2";
import asset from "./content/asset";
import deploy from "./deploy";
import pricingplan from "./pricingplan";
import support from "./support";
import comment from "./comment";
import globalnotification from "./globalnotification";
import analytics from "./analytics";

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  user,
  content,
  head1,
  head2,
  asset,
  deploy,
  pricingplan,
  support,
  comment,
  globalnotification,
  analytics
});
