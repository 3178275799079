import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BASE_SERVER_URL } from "../../defaults";

const DashboardActions = ({ priviledge }) => {
  if (priviledge == "Master Administrator") {
    // master admin
    return (
      <div className="dash-buttons">
        <h2 className="text-primary">Users</h2>

        <Link to="/users" className="btn btn-light">
          Add or Modify Users
        </Link>
        <br />
        <h2 className="text-primary">Content</h2>
        <Link to="/upload" className="btn btn-light">
          Upload Byts Schema Spreadsheet
        </Link>
        <Link to="/deploy" className="btn btn-deprecated">
          Deploy Content (DEPRECATED - Upload Byts Schema Spreadsheet Instead)
        </Link>
        <Link to="/content-page" className="btn btn-deprecated">
          Add or Modify Content (DEPRECATED - Upload Byts Schema Spreadsheet
          Instead)
        </Link>

        <br />

        <h2 className="text-primary">Support</h2>
        <Link to="/support/1" className="btn btn-light">
          Manage Unresolved Support Tickets
        </Link>
        <Link to="/support/2" className="btn btn-light">
          Manage Resolved Support Tickets
        </Link>

        <br />

        <h2 className="text-primary">Comments</h2>
        <Link to="/comment/1" className="btn btn-light">
          Manage Unresolved Comment Threads
        </Link>
        <Link to="/comment/2" className="btn btn-light">
          Manage Resolved Comment Threads
        </Link>

        <br />

        <h2 className="text-primary">Pricing Plans</h2>
        <Link to="/pricingplans" className="btn btn-light">
          Add and Modify Pricing Plans
        </Link>
        <Link to="/deploypricingplans" className="btn btn-light">
          Deploy Pricing Plans
        </Link>

        <br />

        <h2 className="text-primary">Global Notifications</h2>
        <Link to="/globalnotifications" className="btn btn-light">
          Add and Modify Global Notifications
        </Link>

        <br />
        <h2 className="text-primary">Offline User Operations</h2>
        <Link to="/uploadofflineusers" className="btn btn-light">
          Upload Offline User List
        </Link>
        <Link to="/offlineusersgroup" className="btn btn-light">
          Staged Subtopic Rollout
        </Link>
        <br />

        <h2 className="text-primary">Migration</h2>

        <Link to="/migrateusers" className="btn btn-light">
          Migrate Users
        </Link>

        <br />
        <h2 className="text-primary">Analytics</h2>
        <Link to="/consolidatedreport" className="btn btn-light">
          Consolidated Report
        </Link>
      </div>
    );
  } else if (priviledge == "Content Creator") {
    // for content creator
    return (
      <div className="dash-buttons">
        <h2 className="text-primary">Content</h2>
        <Link to="/deploy" className="btn btn-deprecated">
          Modify the Dev Schema (DEPRECATED - Ask access for the Byts Schema
          Spreadsheet)
        </Link>
        <Link to="/content-page" className="btn btn-deprecated">
          Add or Modify Content (DEPRECATED - Ask access for the Byts Schema
          Spreadsheet)
        </Link>
      </div>
    );
  } else {
    return (
      <div className="dash-buttons">
        <Link to="!#" className="btn btn-light">
          <i className="fas fa-user-circle text-primary" /> Invalid Setup.
          Contact Master Admin.
        </Link>
      </div>
    );
  }
};

DashboardActions.propTypes = {
  priviledge: PropTypes.string.isRequired,
};

export default DashboardActions;
