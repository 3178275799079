import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import PricingPlanItem from "./PricingPlansItem";
import { getPricingPlans, deployPricingPlans } from "../../actions/pricingplan";
import PricingPlanForm from "./PricingPlanForm";
import { Link } from "react-router-dom";

/*
 {pricingplans.map(pricingplan => (
            <PricingPlanItem key={pricingplan._id} pricingplan={pricingplan} />
          ))}
*/
const DeployPricingPlans = ({
  getPricingPlans,
  pricingplan: {
    pricingplans,
    loading
  },
  deployPricingPlans
}) => {
  useEffect(() => {
    getPricingPlans();
  }, [getPricingPlans]);

  console.log(pricingplans);

  var plansNeeded = ["plan1", "plan2", "plan3", "plan4", "plan5", "plan6", "plan7"];
  var plansError = [];

  var plansSuccess = [];

  if (pricingplans) {
    console.log("1");

    if (pricingplans.plans) {
      console.log("2");

      if (pricingplans.plans.length < plansNeeded.length) {
        plansError.push("You need to push " + plansNeeded.length + " Plans to PRODUCTION! But you have only " + pricingplans.plans.length + " Plans Definition added here!");
      } else if (pricingplans.plans.length > plansNeeded.length) {
        plansError.push("You need to push " + plansNeeded.length + " Plans to PRODUCTION! But you have " + pricingplans.plans.length + " Plans Definition added here!");
      }

      pricingplans.plans.forEach(plan => {
        console.log("3");

        if (plan.planId) {
          if (plansNeeded.includes(plan.planId)) {
            plansSuccess.push(plan.planId + " FOUND!");
          }
        } else {
          plansError.push(plan.title + " has NO PlanId!");
        }
      });
    }
  }
  return loading && !pricingplans ? (
    <Spinner />
  ) : (
      <Fragment>
        <h1 className="large text-primary">Pricing Plans</h1>

        <div className="posts">
          {
            pricingplans && pricingplans.contentMappingArray && pricingplans.contentMappingArray.length > 0 ? pricingplans.contentMappingArray.map(status => (
              <h4 style={{ color: "#000000" }}>{status}</h4>
            )) : <h4 style={{ color: "#000000" }}>No Content Schema Map Found!</h4>
          }
        </div>
        <br />
        <div className="posts">
          {
            pricingplans && pricingplans.errorArray && pricingplans.statusArray.length > 0 ? pricingplans.statusArray.map(status => (
              <h4 style={{ color: "#000000" }}>{status}</h4>
            )) : <h4 style={{ color: "#000000" }}>No Dev Pricing Plan Data available!</h4>
          }
        </div>
        <br />
        <div className="posts">
          {
            pricingplans && pricingplans.errorArray && pricingplans.errorArray.length > 0 ? pricingplans.errorArray.map(error => (
              <h4 style={{ color: "#ff0000" }}>{error}</h4>
            )) : <h4 style={{ color: "#008000" }}>No Issues found with the Dev Pricing Schema! Ready for Production Push!</h4>
          }
        </div>
        <br />
        <button className="btn btn-primary"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Revalidate the Dev Pricing Plans
        </button>

        <br />
        <br />

        <button className="btn btn-primary"
          onClick={() => {
            deployPricingPlans();
          }}
        >
          Deploy to PRODUCTION!
        </button>




      </Fragment>
    );
};

DeployPricingPlans.propTypes = {
  getPricingPlans: PropTypes.func.isRequired,
  pricingplan: PropTypes.object.isRequired,
  deployPricingPlans: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pricingplan: state.pricingplan
});

export default connect(mapStateToProps, { getPricingPlans, deployPricingPlans })(DeployPricingPlans);
