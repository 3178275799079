import {
  GET_USERS,
  GET_USER,
  USER_ERROR,
  UPDATE_USER,
  DELETE_USER,
  GET_SOURCEUSERINFO,
  ERROR_SOURCEUSERINFO,
  GET_DESTUSERINFO,
  ERROR_DESTUSERINFO,
  CLEAR_MIGRATEPAGE,
  MIGRATION_INPROGRESS,
  MIGRATION_DONE,
  GET_GROUPS,
  GROUPS_ERROR,
  GET_GROUP,
  GROUP_ERROR,
  UPDATE_GROUP,
  UPDATE_GROUP_ERROR,
  CLEAR_GROUPS,
} from "../actions/types";
import { model } from "mongoose";

const initialState = {
  user: null,
  users: [],
  loading: true,
  group: {},
  loadingGroup: true,
  groups: [],
  loadingGroups: true,
  error: {},
  sUserInfo: "",
  loadingSUserInfo: true,
  dUserInfo: "",
  loadingDUserInfo: true,
  migration: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case GET_USER:
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
        loading: true, // TODO: Check this once. true enables proper working of the edit-user page. HOW is this possible?
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== payload),
        loading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case GET_GROUPS:
      return {
        ...state,
        groups: payload,
        loadingGroups: false, // TODO: Check this once. true enables proper working of the edit-user page. HOW is this possible?
      };

    case GROUPS_ERROR:
      return {
        ...state,
        error: payload,
        loadingGroups: false,
      };

    case GET_GROUP:
      return {
        ...state,
        group: payload,
        loadingGroup: false, // TODO: Check this once. true enables proper working of the edit-user page. HOW is this possible?
      };
    case UPDATE_GROUP_ERROR:
    case GROUP_ERROR:
      return {
        ...state,
        error: payload,
        loadingGroup: false,
      };

    case UPDATE_GROUP:
      return {
        ...state,
        group: { ...state.group, allowedSubtopics: payload.allowedSubtopics },
        loadingGroup: false,
      };

    case CLEAR_GROUPS:
      return {
        ...state,
        group: {},
        loadingGroups: false,
      };
    case GET_SOURCEUSERINFO:
      return {
        ...state,
        sUserInfo: payload,
        loadingSUserInfo: false,
      };
    case ERROR_SOURCEUSERINFO:
      return {
        ...state,
        error: payload,
        loadingSUserInfo: false,
      };
    case GET_DESTUSERINFO:
      return {
        ...state,
        dUserInfo: payload,
        loadingDUserInfo: false,
      };
    case ERROR_DESTUSERINFO:
      return {
        ...state,
        error: payload,
        loadingDUserInfo: false,
      };
    case CLEAR_MIGRATEPAGE:
      return {
        ...state,
        sUserInfo: "",
        loadingSUserInfo: true,
      };

    case MIGRATION_INPROGRESS:
      return {
        ...state,
        migration: 1,
      };
    case MIGRATION_DONE:
      return {
        ...state,
        migration: payload.mode,
      };
    default:
      return state;
  }
}
