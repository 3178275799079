import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import GroupsItem from "./GroupsItem";
import { getGroups, clearGroups } from "../../actions/user";
import { Link } from "react-router-dom";

const Groups = ({
  getGroups,
  clearGroups,
  user: { groups, loadingGroups },
}) => {
  useEffect(() => {
    //clearGroups();
    getGroups();
  }, [getGroups, clearGroups]);
  return loadingGroups ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Offline User Groups</h1>
      <div className="posts">
        {groups && groups.groups && groups.groups.length > 0 ? (
          groups.groups.map((group) => (
            <GroupsItem key={group._id} group={group} />
          ))
        ) : (
          <p>Unable to find any user with a set groupId</p>
        )}
      </div>
      <Link className="btn btn-light my-1" to="/dashboard">
        Go Back
      </Link>
    </Fragment>
  );
};

Groups.propTypes = {
  clearGroups: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { getGroups, clearGroups })(Groups);
