import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createGlobalNotification } from "../../actions/globalnotification";
import { setAlert } from "../../actions/alert";
import { Checkbox } from "semantic-ui-react";

const CreateGlobalNotification = ({
  createGlobalNotification,
  profile: { profile, loading },
  history,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    text: "",
    plansList: [
      "plan1 (C)",
      "plan2 (C++)",
      "plan3 (Data Structures and Algorithms)",
      "plan4 (Python)",
      "plan5 (C, Data Structures and Algorithms)",
      "plan6 (C, C++, Data Structures and Algorithms)",
      "plan7 (C, C++, Data Structures and Algorithms, Python)",
    ],
    listOfSelectedPlans: [false, false, false, false, false, false, false],
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  const { text, plansList, listOfSelectedPlans } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    createGlobalNotification(formData, history);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading === null ? (
    <Redirect to="/dashboard" />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Add a Global Notification</h1>
      <p className="lead">
        <i className="fas fa-globalnotification" /> Let's get some information
        to create a new Global Notification
      </p>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Text"
            name="text"
            value={text}
            onChange={(e) => onChange(e)}
            isRequired
          />
          <small className="form-text">
            Enter the new Global Notification's Text
          </small>
        </div>

        <p className="">
          Mark the plan holders to whom the above notification has to be sent
          <small className="form-text">
            Dont select any plan to send notifications to everyone
          </small>
        </p>

        {plansList && plansList.length > 0 ? (
          plansList.map((plan, id) => (
            <p>
              <Checkbox
                onChange
                label={"   " + plan}
                checked={listOfSelectedPlans[id]}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                onChange={(e, { checked }) => {
                  // eslint-disable-next-line no-lone-blocks
                  {
                    var tempPlansList = listOfSelectedPlans;
                    tempPlansList[id] = checked;

                    setFormData({
                      ...formData,
                      listOfSelectedPlans: tempPlansList,
                    });
                  }
                }}
              />{" "}
            </p>
          ))
        ) : (
          <p>This group has access to all the subtopics</p>
        )}

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/globalnotifications">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

CreateGlobalNotification.propTypes = {
  createGlobalNotification: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  setAlert,
  createGlobalNotification,
})(withRouter(CreateGlobalNotification));
