import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deletePricingPlan } from "../../actions/pricingplan";

const PricingPlanItem = ({
  deletePricingPlan,
  auth,
  identified,
  pricingplan: { _id, title, tagline, price, days, validityString, planId, planType, contents },
  showActions
}) => (
    <div className="bg-white p-1 my-1">
      <div className="useritem ">
        <div>
          <Link to={`#!`}>
            <h4>{title}</h4>
            <p>{planType ? <div>Ribbon Text: {planType}</div> : <Fragment></Fragment>}</p>
            {identified ? <h4 style={{ color: "#008000" }}>Identified</h4> : <h4 style={{ color: "#ff0000" }}>Unidentified</h4>}

            <p style={{ color: "#000000" }}>Plan ID: <b>{planId}</b></p>
          </Link>
        </div>
        <div style={{ paddingLeft: "100px" }}>
          <p >Tagline: {tagline} </p>
          <p >Price: ₹ {price}</p>
          <p >Validity: {days} Days!</p>
          <p >Validity String: {validityString}</p>

          {contents && contents.length > 0 ? <h5>This plan includes</h5> : <div></div>}
          {contents && contents.length > 0 && contents.map(content => (<p>{content}</p>))}

        </div>
      </div>
      <br />
      <Fragment>
        <Link to={`/edit-pricingplan/${_id}`} className="btn btn-primary">
          Edit Pricing Plan
          </Link>
        <br />

        <button
          onClick={() => {
            deletePricingPlan(_id); window.location.reload(false);
          }}
          type="button"
          className="btn btn-danger"
        >
          Delete Pricing Plan
            </button>
      </Fragment>

    </div>
  );

PricingPlanItem.defaultProps = {
  showActions: true
};

PricingPlanItem.propTypes = {
  pricingplan: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePricingPlan: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deletePricingPlan })(PricingPlanItem);
