import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { markAsDone } from "../../actions/comment";

const CommentItem = ({
  markAsDone,
  auth,
  comment: { _id, assetTitle, assetType, assetId, comments, date, served },
  showActions,
  mode
}) => {

  const [open, setOpen] = React.useState(false)

  return <div className="useritem bg-white p-1 my-1">

    <div>
      <Link to={`#!`}>
        <h4>{assetTitle}</h4>

        <h4>{assetType}</h4>

        {served ?
          <p className="lead">
            Done
          </p> :
          <p className="lead">
            Pending
            </p>
        }
      </Link>
    </div>
    <div>
      <h4>Asset ID: {assetId}</h4>
      {comments && comments.length > 0 && comments[0] && comments[0].by ? <p >Last Comment by {comments[0].by}</p>

        : <Fragment></Fragment>}


      <p className="post-date">
        {comments && comments.length > 0 && comments[0] && comments[0].at ? <Moment fromNow date={comments[0].at} /> : <Fragment></Fragment>}
        <br />
        {comments && comments.length > 0 && comments[0] && comments[0].at ? <p> {comments[0].at}</p> : <Fragment></Fragment>}
      </p>
    </div>
    <Fragment>



      <p>
        <Link
          to={"/showcomments/" + mode + "/" + assetId}
          className="btn btn-success"
        >
          Manage
            </Link>

      </p>

    </Fragment>
  </div >


};

CommentItem.defaultProps = {
  showActions: true
};

CommentItem.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  markAsDone: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { markAsDone })(CommentItem);
