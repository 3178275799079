import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import {
    getConsolidatedReport
} from "../../actions/analytics";
import Parser from 'html-react-parser';

import { Link, withRouter, Redirect } from "react-router-dom";

const ConsolidatedReport = ({
    getConsolidatedReport,
    analytics: { analytics, loading },
    auth: { user }
}) => {
    const [formData, setFormData] = useState({});

    useEffect(() => {
        getConsolidatedReport();
    }, [getConsolidatedReport]);

    function ShowConsolidatedReport() {
        if (analytics && analytics.cReport)
            return (
                <div>{Parser(analytics.cReport)}</div>
            );
        else
            return (
                <Fragment>
                    <h1 className="large text-primary">Consolidated Report</h1>
                    <p className="lead-nobottom">
                        Unable to fetch the Consolidated Report.
          </p>


                </Fragment>
            );
    }


    return loading || !analytics ? (
        <Spinner />
    ) : (
            <Fragment>
                <ShowConsolidatedReport />
            </Fragment>
        );
};

ConsolidatedReport.propTypes = {
    getConsolidatedReport: PropTypes.func.isRequired,
    analytics: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    analytics: state.analytics,
    auth: state.auth
});

export default connect(mapStateToProps, {
    getConsolidatedReport
})(withRouter(ConsolidatedReport));
