import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import {
  GET_SUPPORTS,
  GET_SUPPORT,
  SUPPORT_ERROR,
  UPDATE_SUPPORT,
  DELETE_SUPPORT
} from "./types";

// Get posts
export const getSupports = (mode) => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/supports/" + mode);

    dispatch({
      type: GET_SUPPORTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SUPPORT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const markAsDone = (_id) => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/supports/markasdone/" + _id);

    dispatch(setAlert("This Support Ticket has been marked as completed!", "success"));

    dispatch({
      type: UPDATE_SUPPORT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SUPPORT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};