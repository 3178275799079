export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

// USER related alerts
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const USER_ERROR = "USER_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";

export const GET_PRICINGPLANS = "GET_PRICINGPLANS";
export const GET_PRICINGPLAN = "GET_PRICINGPLAN";
export const PRICINGPLAN_ERROR = "PRICINGPLAN_ERROR";
export const UPDATE_PRICINGPLAN = "UPDATE_PRICINGPLAN";
export const DELETE_PRICINGPLAN = "DELETE_PRICINGPLAN";
export const ADD_PRICINGPLAN = "ADD_PRICINGPLAN";
export const DEPLOY_PRICINGPLANS = "DEPLOY_PRICINGPLANS";

export const GET_GLOBALNOTIFICATIONS = "GET_GLOBALNOTIFICATIONS";
export const GET_GLOBALNOTIFICATION = "GET_GLOBALNOTIFICATION";
export const GLOBALNOTIFICATION_ERROR = "GLOBALNOTIFICATION_ERROR";
export const UPDATE_GLOBALNOTIFICATION = "UPDATE_GLOBALNOTIFICATION";
export const DELETE_GLOBALNOTIFICATION = "DELETE_GLOBALNOTIFICATION";
export const ADD_GLOBALNOTIFICATION = "ADD_GLOBALNOTIFICATION";
export const DEPLOY_GLOBALNOTIFICATIONS = "DEPLOY_GLOBALNOTIFICATIONS";

// CONTENT related ACtions
export const GET_CONTENT = "GET_CONTENT";
export const SET_CONTENT = "SET_CONTENT";
export const CONTENT_ERROR = "CONTENT_ERROR";
export const GET_HEAD1S = "GET_HEAD1S";
export const GET_HEAD1 = "GET_HEAD1";
export const HEAD1_ERROR = "HEAD1_ERROR";
export const UPDATE_HEAD1 = "UPDATE_HEAD1";
export const DELETE_HEAD1 = "DELETE_HEAD1";
export const ADD_HEAD1 = "ADD_HEAD1";

export const GET_HEAD2S = "GET_HEAD2S";
export const GET_HEAD2 = "GET_HEAD2";
export const HEAD2_ERROR = "HEAD2_ERROR";
export const UPDATE_HEAD2 = "UPDATE_HEAD2";
export const DELETE_HEAD2 = "DELETE_HEAD2";
export const ADD_HEAD2 = "ADD_HEAD2";

export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSET = "GET_ASSET";
export const ASSET_ERROR = "ASSET_ERROR";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const DELETE_ASSET = "DELETE_ASSET";
export const ADD_ASSET = "ADD_ASSET";

export const PUSH_TO_PROD = "PUSH_TO_PROD";
export const GET_DEVDEPLOY_SCHEME = "GET_DEVDEPLOY_SCHEME";
export const UPDATE_DEVDEPLOY_SCHEME = "UPDATE_DEVDEPLOY_SCHEME";
export const GET_DEPLOY_SCHEME = "GET_DEPLOY_SCHEME";
export const UPDATE_DEPLOY_SCHEME = "UPDATE_DEPLOY_SCHEME";
export const DEPLOY_SCHEME_ERROR = "DEPLOY_SCHEME_ERROR";
export const DELETE_DEPLOY_ITEM = "DELETE_DEPLOY_ITEM";

export const GET_SUPPORTS = "GET_SUPPORTS";
export const GET_SUPPORT = "GET_SUPPORT";
export const SUPPORT_ERROR = "SUPPORT_ERROR";
export const UPDATE_SUPPORT = "UPDATE_SUPPORT";
export const DELETE_SUPPORT = "DELETE_SUPPORT";

export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENT = "GET_COMMENT";
export const COMMENT_ERROR = "COMMENT_ERROR";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export const GET_SHOWCOMMENTS = "GET_SHOWCOMMENTS";
export const SHOWCOMMENT_ERROR = "SHOWCOMMENT_ERROR";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";

export const GET_CREPORT = "GET_CREPORT";
export const ERROR_CREPORT = "ERROR_CREPORT";

export const GET_SOURCEUSERINFO = "GET_SOURCEUSERINFO";
export const ERROR_SOURCEUSERINFO = "ERROR_SOURCEUSERINFO";
export const GET_DESTUSERINFO = "GET_DESTUSERINFO";
export const ERROR_DESTUSERINFO = "ERROR_DESTUSERINFO";
export const CLEAR_MIGRATEPAGE = "CLEAR_MIGRATEPAGE";

export const MIGRATION_INPROGRESS = "MIGRATION_INPROGRESS";
export const MIGRATION_DONE = "MIGRATION_DONE";

export const GET_GROUPS = "GET_GROUPS";
export const GROUPS_ERROR = "GROUPS_ERROR";
export const GET_GROUP = "GET_GROUP";
export const GROUP_ERROR = "GROUP_ERROR";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

export const CLEAR_GROUPS = "CLEAR_GROUPS";
