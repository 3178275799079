import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateDevDeployScheme } from "../../actions/deploy";
import Spinner from "../layout/Spinner";
const AddDeploy = ({
  getAllDeployScheme,
  updateDevDeployScheme,
  content: {
    content: { listOfHead1s, listOfHead2s, listOfAssets },
    loading
  },
  deploy,
  match,
  history
}) => {
  const [formData, setFormData] = useState({
    title: "",
    id: "",
    index: "",
    parent: "",
    toAddTitle: "",
    toAdd: []
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  var { title, id, index, parent, toAddTitle, toAdd } = formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    e.preventDefault();
    console.log("Sending Parent: " + parent);
    updateDevDeployScheme(
      formData,
      history,
      match.params.id,
      match.params.index,
      match.params.topicid
    );
  };

  function ShowOptions() {
    return !toAdd ? (
      <Spinner />
    ) : (
      <div className="form-group">
        <select name="parent" value={parent} onChange={e => onChange(e)}>
          <option>* Select the {toAddTitle} to add here.</option>
          {toAdd.map(toAddItem => {
            return <option value={toAddItem.id}>{toAddItem.title}</option>;
          })}
        </select>
        <small className="form-text">
          Give us an idea of where you are at in your career
        </small>
      </div>
    );
  }
  useEffect(() => {
    console.log("useEffect in AddDeploy");
    console.log(
      "Done: " +
        match.params.topicid +
        " " +
        match.params.id +
        " " +
        match.params.index
    );

    parent = "Head";
    toAddTitle = "Topic";
    toAdd = listOfHead1s;

    if (match.params.id === "head") {
      // we are looking  for head1 object. leave as is
    } else if (match.params.id !== "head" && match.params.topicid === "head") {
      // we are looking for head2s
      toAddTitle = "Subtopic";
      toAdd = listOfHead2s;
    } else if (match.params.id !== "head" && match.params.topicid !== "head") {
      // we are looking for head2s
      toAddTitle = "Asset";
      toAdd = listOfAssets;
    }

    setFormData({
      id: match.params.id,
      index: match.params.index,
      parent: parent,
      toAdd,
      toAddTitle
    });
  }, [loading, match]);

  console.log("ToAdd: " + toAdd);
  return (
    <Fragment>
      <h1 className="large text-primary">Add a node</h1>
      <p className="lead">
        <i class="far fa-plus-square"></i> Let's get some information to create
        a new one
      </p>

      <form className="form" onSubmit={e => onSubmit(e)}>
        <ShowOptions />
        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/deploy">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

AddDeploy.propTypes = {
  updateDevDeployScheme: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  content: state.content
});
export default connect(mapStateToProps, { updateDevDeployScheme })(
  withRouter(AddDeploy)
);
