import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Head2Item from "./Head2Item";
import { getHead2s } from "../../actions/content/head2";
import { Link } from "react-router-dom";

const Head2s = ({ getHead2s, head2: { head2s, loading } }) => {
  useEffect(() => {
    getHead2s();
  }, [getHead2s]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Subtopic</h1>
      <Link to="/create-head2" className="btn btn-primary">
        <i className="fas fa-user-plus"></i> Add a new one
      </Link>
      <Link className="btn btn-light my-1" to="/content-page">
        Go Back
      </Link>
      <div className="posts">
        {head2s.map(head2 => (
          <Head2Item key={head2._id} head2={head2} />
        ))}
      </div>
    </Fragment>
  );
};

Head2s.propTypes = {
  getHead2s: PropTypes.func.isRequired,
  head2: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  head2: state.head2
});

export default connect(mapStateToProps, { getHead2s })(Head2s);
