import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import DashboardActions from "./DashboardActions";
import Experience from "./Experience";
import Education from "./Education";
import { getCurrentProfile, deleteAccount } from "../../actions/profile";
import { getCurrentUser } from "../../actions/user";
import { getContent } from "../../actions/content";
import { getAllDeployScheme } from "../../actions/deploy";
const Dashboard = ({
  getCurrentUser,
  getCurrentProfile,
  getAllDeployScheme,
  getContent,

  deleteAccount,
  auth: { user },
  profile: { profile, loading }
}) => {
  useEffect(() => {
    getContent();
    getCurrentProfile();
    getAllDeployScheme();
  }, [getCurrentProfile, getCurrentUser, getAllDeployScheme]);
  return loading || profile === null || user == null ? (
    <Spinner />
  ) : (
      <Fragment>
        <h1 className="large text-primary">Dashboard</h1>
        <p className="lead">
          <i className="fas fa-user" /> Welcome {user && user.name}
        </p>

        <p className="lead">
          You are a {user.priviledge}!
      </p>

        {profile !== null ? (
          <Fragment>
            <DashboardActions priviledge={user.priviledge} />
          </Fragment>
        ) : (
            <Fragment>
              <p>You have not yet setup a profile, please add some info</p>
              <Link to="/create-profile" className="btn btn-primary my-1">
                Create Profile
          </Link>
            </Fragment>
          )}
      </Fragment>
    );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getAllDeployScheme: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, {
  getCurrentUser,
  getCurrentProfile,
  deleteAccount,
  getContent,
  getAllDeployScheme
})(Dashboard);
