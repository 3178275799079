import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deleteHead1 } from "../../actions/content/head1";

const Head1Item = ({
  deleteHead1,
  auth,
  head1: { _id, title },
  showActions
}) => (
  <div className=" bg-white p-1 my-1">
    <h2>{title}</h2>
    <br />
    {showActions && (
      <div className="aParent">
        <p>
          <Link to={`/edit-head1/${_id}`} className="btn btn-primary">
            Edit Topic
          </Link>
        </p>
        <br />

        <p>
          <button
            onClick={() => deleteHead1(_id)}
            type="button"
            className="btn btn-danger"
          >
            Delete Topic
          </button>
        </p>
      </div>
    )}
  </div>
);

Head1Item.defaultProps = {
  showActions: true
};

Head1Item.propTypes = {
  head1: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteHead1 })(Head1Item);
