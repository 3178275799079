import axios from "axios";

import { setAlert } from "./alert";
import {
  GET_CREPORT,
  ERROR_CREPORT
} from "./types";

// Create or update user
export const getConsolidatedReport = () => async dispatch => {
  console.log("getConsolidatedReport");

  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.get(
      "/api/analytics/consolidatedreport/",
      config
    );

    dispatch({
      type: GET_CREPORT,
      payload: res.data
    });

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: ERROR_CREPORT,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
