import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentGroup, updateCurrentGroup } from "../../actions/user";
import { setAlert } from "../../actions/alert";
import { Checkbox } from "semantic-ui-react";

const EditGroup = ({
  user: { group, loadingGroup },
  createUser,
  getCurrentGroup,
  updateCurrentGroup,
  history,
  match,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    listOfAllowedSubtopicIds: [],
    listOfSubtopicsChecks: [],
    listOfCurrentAllowedSubtopics: [],
  });

  const {
    listOfAllowedSubtopicIds,
    listOfSubtopicsChecks,
    listOfCurrentAllowedSubtopics,
  } = formData;

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    console.log("useEffect");
    getCurrentGroup(match.params.id);

    var tempListOfSubtopicsChecks = [];
    var tempListOfCurrentAllowedSubtopics = [];

    if (group && group.subtopics) {
      for (var idx in group.subtopics) {
        if (
          group.allowedSubtopics &&
          group.allowedSubtopics.includes(group.subtopics[idx])
        ) {
          tempListOfSubtopicsChecks.push(true);
          tempListOfCurrentAllowedSubtopics.push(group.subtopics[idx]);
        } else {
          tempListOfSubtopicsChecks.push(false);
        }
      }
    }

    setFormData({
      ...formData,
      listOfSubtopicsChecks: tempListOfSubtopicsChecks,
      listOfCurrentAllowedSubtopics: tempListOfCurrentAllowedSubtopics,
    });
  }, [loadingGroup, getCurrentGroup, match]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    // if (displaySocialInputs && password && password.length >= 6) {
    //   createUser(formData, history, true, user._id);
    // } else if (!displaySocialInputs) {
    //   createUser(formData, history, true, user._id);
    // } else {
    //   setAlert("Password should have a minimum of 6 characters", "danger");
    // }
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit this User Group</h1>
      <h1 className="large text-primary">
        {group ? group.id : <p>Unknown</p>}
      </h1>
      <b className="">Currently Active Allowed Subtopic IDs</b>
      <p className="">
        {group &&
        group.allowedSubtopics &&
        group.allowedSubtopics.length > 0 ? (
          group.allowedSubtopics.map((allowedSubtopic) => (
            <li>{allowedSubtopic}</li>
          ))
        ) : (
          <p>This group has access to all the subtopics</p>
        )}
      </p>

      <br />
      <b className="">
        Modify Allowed Subtopic IDs (Mark the subtopics to which this group
        should have access)
      </b>
      {group && group.subtopics && group.subtopics.length > 0 ? (
        group.subtopics.map((subtopic, id) => (
          <p>
            <Checkbox
              onChange
              label={"   " + subtopic}
              checked={listOfSubtopicsChecks[id]}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              onChange={(e, { checked }) => {
                // eslint-disable-next-line no-lone-blocks
                {
                  var tempListOfCurrentAllowedSubtopics = [];

                  var tempListOfSubtopicsChecks1 = listOfSubtopicsChecks;
                  tempListOfSubtopicsChecks1[id] = checked;

                  for (var checksIdx in tempListOfSubtopicsChecks1) {
                    if (tempListOfSubtopicsChecks1[checksIdx] == true) {
                      if (
                        group &&
                        group.subtopics &&
                        group.subtopics.length > 0
                      ) {
                        tempListOfCurrentAllowedSubtopics.push(
                          group.subtopics[checksIdx]
                        );
                      }
                    }
                  }

                  setFormData({
                    ...formData,
                    listOfSubtopicsChecks: tempListOfSubtopicsChecks1,
                    listOfCurrentAllowedSubtopics: tempListOfCurrentAllowedSubtopics,
                  });
                }
              }}
            />{" "}
          </p>
        ))
      ) : (
        <p>This group has access to all the subtopics</p>
      )}
      <br />
      <b className="">New Allowed Subtopic IDs (Based on the above Checkbox)</b>
      <p className="">
        {listOfCurrentAllowedSubtopics &&
        listOfCurrentAllowedSubtopics.length > 0 ? (
          listOfCurrentAllowedSubtopics.map((allowedSubtopic) => (
            <li>{allowedSubtopic}</li>
          ))
        ) : (
          <p>This group has access to all the subtopics</p>
        )}
      </p>

      <br />

      <button
        className="btn btn-primary"
        onClick={() => {
          console.log("-> " + match.params.id);
          console.log(listOfCurrentAllowedSubtopics);

          updateCurrentGroup({
            id: match.params.id,
            subtopics: listOfCurrentAllowedSubtopics,
          });
        }}
      >
        Update Group
      </button>
      <br />
      <Link className="btn btn-light my-1" to="/offlineusersgroup">
        Go Back
      </Link>
    </Fragment>
  );
};

EditGroup.propTypes = {
  updateCurrentGroup: PropTypes.func.isRequired,
  getCurrentGroup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  setAlert,
  getCurrentGroup,
  updateCurrentGroup,
})(withRouter(EditGroup));
