import React, { Component, Fragment } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import axios from "axios";
import { Controlled as CodeMirror } from "react-codemirror2";
import { useHistory } from 'react-router-dom';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      verifyUploaded: false,
      successfullUploaded: false,
      status: "Waiting for you to upload your <OfflineUsers>.xlsx..."
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.verifyFiles = this.verifyFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    console.log("onFilesAdded");
    if (this.state.files.length < 1) {
      console.log("Trying to add!");

      if (files[0].name) {
        this.setState({
          files: this.state.files.concat(files),
          status: "You can continue to upload the file"
        });
      } else {
        this.setState({
          status: "You cannot add this file. Ensure that it is of type (.xlsx)"
        });
      }
    }
  }

  async verifyFiles() {
    if (this.state.files.length == 0) {
      this.setState({ status: "You have not added a file yet!" });
      return;
    }
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    var file = this.state.files[0];

    console.log(file);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const formData = new FormData();
      formData.append("file", file, file.name);

      const res = await axios.post(
        "/api/offlineusers/verify/",
        formData,
        config
      );

      console.log("Response");
      console.log(res);

      if (res.data == "failure") {
        this.setState({ verifyUploaded: true, uploading: false, status: "FAILED" });
      } else {
        this.setState({
          verifyUploaded: true,
          uploading: false,
          status: "Done! Your User List has been VERIFIED! Check for any errors. If OKAY, continue to Push to Production.\n\n" + res.data
        });
      }
    } catch (err) {
      console.log(err);

    };

  }

  async uploadFiles() {
    if (this.state.files.length == 0) {
      this.setState({ status: "You have not added a file yet!" });
      return;
    }

    if (window.confirm("Are you sure you want to push the Offline Users List to Production?")) {
      this.setState({ uploadProgress: {}, uploading: true });
      const promises = [];
      var file = this.state.files[0];
      try {
        const config = {
          headers: {
            "Content-Type": "application/json"
          }
        };

        const formData = new FormData();
        formData.append("file", file, file.name);

        const res = await axios.post(
          "/api/offlineusers/upload/",
          formData,
          config
        );

        console.log("Response");
        console.log(res);

        if (res.data == "failure") {
          this.setState({ successfullUploaded: true, uploading: false, status: "FAILED" });
        } else {
          this.setState({
            successfullUploaded: true,
            uploading: false,
            status: "Done! Your Offline Users List has been PUSHED to Production!\n\n" + res.data
          });
        }
      } catch (err) {
        console.log(err);

      };
    }


  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        console.log("progress");
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", (event) => {
        console.log("load");
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        console.log(req);
        resolve(req);
      });

      req.upload.addEventListener("error", (event) => {
        console.log("error");
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open("POST", "http://192.168.0.107:5000/api/deploy/upload");
      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0,
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <Fragment>

          <button className="btn btn-deploy"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            Clear
        </button>
        </Fragment>);
    } else if (this.state.verifyUploaded) {
      return (
        <Fragment>
          <button className="btn btn-success"
            disabled={this.state.files.length < 0 || this.state.uploading}
            onClick={this.uploadFiles}
          >
            Push to Production
    </button>
          <br />
          <button className="btn btn-deploy"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            Clear
        </button>
        </Fragment>

      );
    } else {
      return (
        <Fragment>
          <button className="btn btn-deploy"
            disabled={this.state.files.length < 0 || this.state.uploading}
            onClick={this.verifyFiles}
          >
            Verify
        </button>
          <br />
        </Fragment>
      );
    }
  }

  render() {
    return (
      <div className="Upload">
        <h1 className="large text-primary">Upload OfflineUsers.xlsx</h1>
        <p>This is an INCREMENTAL upload. You need not provide a list of all the students in the offline medium all the time. Just provide the new users list.</p>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map((file) => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}
        </div>
        <div className="Content">

          <pre>{this.state.status}</pre>
        </div>

      </div>
    );
  }
}

export default Upload;
