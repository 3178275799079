import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import CommentItem from "./ShowCommentsItem";
import { getShowComments, addComment, markAsDone } from "../../actions/comment";
import { Link, Redirect } from "react-router-dom";

const ShowComments = ({ getShowComments, comment: { showComments, loadingShowComments }, addComment, match, markAsDone }) => {
  useEffect(() => {

    getShowComments(match.params.assetId);
  }, [getShowComments]);

  const [formData, setFormData] = useState({
    commentText: "",

  });

  const { commentText } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (commentText) {
      addComment({ assetId: showComments.assetId, comment: commentText });

      setFormData({ ...formData, commentText: "" })
    }
  };
  return loadingShowComments ? (
    <Spinner />
  ) : (
      <Fragment>
        {match.params && match.params.mode && match.params.mode == "1" ? <Link className="btn btn-light my-1" to="/comment/1">
          Go Back
        </Link> : <Link className="btn btn-light my-1" to="/comment/2">
            Go Back
        </Link>}


        <br />
        {showComments && showComments.assetTitle ? <h1 className="large text-primary">{showComments.assetTitle}</h1> : <Fragment></Fragment>}
        {showComments && showComments.assetType ? <h3>Type: {showComments.assetType}</h3> : <Fragment></Fragment>}
        {showComments && showComments.assetId ? <h3 >AssetID: {showComments.assetId}</h3> : <Fragment></Fragment>}
        {showComments && showComments.subtopicId ? <h3 >SubtopicID: {showComments.subtopicId}</h3> : <Fragment></Fragment>}
        {showComments && showComments.desc ? <h3 >Description: {showComments.desc}</h3> : <Fragment></Fragment>}

        {showComments && showComments.subtopicId && showComments.assetId ? <a href={"https://www.byts.co.in/learn/" + showComments.subtopicId + "/" + showComments.assetId}>Take me to the associated Learn Page</a> : <Fragment></Fragment>}
        {showComments && showComments.comments && showComments.comments.length == 0 && (match.params.mode == "1" ? <p>Awesome! You dont have any Unresolved Comments!</p> : <p>Awesome! You dont have any Resolved Comments!</p>)}

        <div className="bg-white p-1 my-1">
          <form className="form" onSubmit={e => onSubmit(e)}>
            <h3>Add a comment</h3>
            <div className="form-group">
              <textarea
                rows={5}
                type="text"
                placeholder="Comment"
                name="commentText"
                value={commentText}
                onChange={e => onChange(e)}
                isRequired
              />
              <small className="form-text">Enter your Comment</small>
            </div>
            <input style={{ marginTop: "0px", marginBottom: "0px" }} type="submit" className="btn btn-primary my-1" />

          </form>
        </div>

        {showComments && showComments.served ? <Fragment></Fragment> :
          <Fragment>
            <p>
              <a
                className="btn btn-success"
                onClick={() => {
                  markAsDone(showComments.assetId);

                }}
              >
                Mark as Done
          </a>
            </p>


          </Fragment>
        }


        <div className="posts">
          {showComments && showComments.comments && showComments.comments.map(comment => (
            <CommentItem key={comment._id} comment={comment} assetId={showComments.assetId} />
          ))}
        </div>
      </Fragment>
    );
};

ShowComments.propTypes = {
  getShowComments: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  addComment: PropTypes.func.isRequired,
  markAsDone: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  comment: state.comment
});

export default connect(mapStateToProps, { getShowComments, addComment, markAsDone })(ShowComments);
