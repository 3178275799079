import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUser, getCurrentUser } from "../../actions/user";
import { setAlert } from "../../actions/alert";
const CreateUser = ({
  createUser,
  getCurrentUser,
  profile: { profile, loading },
  history,
  setAlert
}) => {
  const [formData, setFormData] = useState({
    priviledge: "",
    name: "",
    email: "",
    password: "",
    password2: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);
  const { name, email, password, password2, priviledge } = formData;
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "danger");
    } else {
      createUser(formData, history);
    }
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return loading && profile === null ? (
    <Redirect to="/dashboard" />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Add a User Profile</h1>
      <p className="lead">
        <i className="fas fa-user" /> Let's get some information to create a new
        Profile
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <select
            name="priviledge"
            value={priviledge}
            onChange={e => onChange(e)}
          >
            <option value="0">* Select User Priviledge</option>
            <option value="Master Administrator">Master Administrator</option>
            <option value="Content Creator">Content Creator</option>
          </select>
          <small className="form-text">
            Select the level of Priviledge that needs to be associated with this
            new account
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new User's name</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={email}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new User's email id</small>
        </div>

        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Need a minimum of 6 characters</small>
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Confirm Password"
            name="password2"
            value={password2}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">
            Ensure that both the passwords match
          </small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/users">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

CreateUser.propTypes = {
  createUser: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  profile: state.profile
});
export default connect(mapStateToProps, {
  setAlert,
  createUser,
  getCurrentUser
})(withRouter(CreateUser));
