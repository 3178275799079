import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import {
  GET_USERS,
  GET_USER,
  USER_ERROR,
  UPDATE_USER,
  DELETE_USER,
  GET_SOURCEUSERINFO,
  ERROR_SOURCEUSERINFO,
  CLEAR_MIGRATEPAGE,
  GET_DESTUSERINFO,
  ERROR_DESTUSERINFO,
  MIGRATION_INPROGRESS,
  MIGRATION_DONE,
  GET_GROUPS,
  GROUPS_ERROR,
  GET_GROUP,
  GROUP_ERROR,
  UPDATE_GROUP,
  UPDATE_GROUP_ERROR,
  CLEAR_GROUPS,
} from "./types";

// Create or update user
export const createUser = (
  formData,
  history,
  edit = false,
  id = null
) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      "/api/users/adduser",
      { ...formData, id },
      config
    );

    dispatch({
      type: GET_USER,
      payload: res.data,
    });

    dispatch(
      setAlert(
        edit
          ? "The User details have been updated successfully"
          : "User was created successfully",
        "success"
      )
    );

    history.push("/users");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get posts
export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/users");

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getGroups = () => async (dispatch) => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/users/groups");

    dispatch({
      type: GET_GROUPS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GROUPS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const clearGroups = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GROUPS,
  });
};

export const getCurrentGroup = (id) => async (dispatch) => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/users/group/" + id);

    dispatch({
      type: GET_GROUP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const updateCurrentGroup = ({ id, subtopics }) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      BASE_SERVER_URL + "/api/users/update-group/" + id,
      {
        subtopics: subtopics,
      },
      config
    );

    dispatch(setAlert("Successfully updated the User Group", "success"));

    dispatch({
      type: UPDATE_GROUP,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Unable to update the User Group", "danger"));

    dispatch({
      type: UPDATE_GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get current user
export const getCurrentUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/user/${id}`);

    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete post
export const deleteUser = (id) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await axios.delete(`/api/users/${id}`);
      dispatch({
        type: DELETE_USER,
        payload: id,
      });

      dispatch(setAlert("User has been removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: USER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

export const getSourceUserInfo = (id) => async (dispatch) => {
  try {
    if (!id || id == "") {
      dispatch(setAlert("Please enter a valid Source Email ID", "danger"));
      return;
    }

    const res = await axios.get("/api/users/userinfo/" + id);

    dispatch({
      type: GET_SOURCEUSERINFO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ERROR_SOURCEUSERINFO,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getDestUserInfo = (id) => async (dispatch) => {
  try {
    if (!id || id == "") {
      dispatch(setAlert("Please enter a valid Destination Email ID", "danger"));
      return;
    }

    const res = await axios.get("/api/users/userinfo/" + id);
    dispatch({
      type: GET_DESTUSERINFO,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ERROR_DESTUSERINFO,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const clearMigratePage = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MIGRATEPAGE,
    payload: {},
  });
};

var migrationInProgress = false;

export const startMigration = (sId, dId) => async (dispatch) => {
  try {
    if (!sId || sId == "" || !dId || dId == "") {
      dispatch(
        setAlert(
          "Please enter a valid Source and Destination Email ID",
          "danger"
        )
      );
      return;
    }

    if (migrationInProgress) {
      return;
    }

    migrationInProgress = true;

    dispatch({
      type: MIGRATION_INPROGRESS,
      payload: {},
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      "/api/users/migrate/",
      {
        sEmailId: sId,
        dEmailId: dId,
      },
      config
    );

    dispatch(
      setAlert(
        "User Progress Migration has been done successfully from " +
          sId +
          " to " +
          dId,
        "success",
        10000
      )
    );

    dispatch({
      type: MIGRATION_DONE,
      payload: { mode: 2 },
    });

    migrationInProgress = false;
  } catch (err) {
    migrationInProgress = false;

    dispatch({
      type: MIGRATION_DONE,
      payload: { mode: 3 },
    });

    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
