import {
  PUSH_TO_PROD,
  GET_DEPLOY_SCHEME,
  UPDATE_DEPLOY_SCHEME,
  GET_DEVDEPLOY_SCHEME,
  UPDATE_DEVDEPLOY_SCHEME,
  DEPLOY_SCHEME_ERROR,
  DELETE_DEPLOY_ITEM
} from "../actions/types";

const initialState = {
  deploy: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DEPLOY_SCHEME:
    case GET_DEVDEPLOY_SCHEME:
    case DELETE_DEPLOY_ITEM:
    case PUSH_TO_PROD:
      return {
        ...state,
        deploy: payload,
        loading: false
      };
    case UPDATE_DEPLOY_SCHEME:
    case UPDATE_DEVDEPLOY_SCHEME:
      return {
        ...state,
        deploy: payload,
        loading: false
      };
    case DEPLOY_SCHEME_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
