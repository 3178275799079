import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import {
  GET_COMMENTS,
  GET_COMMENT,
  COMMENT_ERROR,
  UPDATE_COMMENT,
  DELETE_COMMENT,

  ADD_COMMENT,
  ADD_COMMENT_ERROR,

  GET_SHOWCOMMENTS,
  SHOWCOMMENT_ERROR,
  DELETE_COMMENT_ERROR
} from "./types";

// Get posts
export const getComments = (mode) => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/comments/" + mode);

    dispatch({
      type: GET_COMMENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Get posts
export const getShowComments = (assetId) => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/comments/comment/" + assetId);

    dispatch({
      type: GET_SHOWCOMMENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SHOWCOMMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const markAsDone = (_id) => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/comments/markasdone/" + _id);

    dispatch(setAlert("This Comment Ticket has been marked as completed!", "success"));


    dispatch({
      type: UPDATE_COMMENT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



export const addComment = ({ comment, assetId }) => async dispatch => {

  console.log("ADDCOMMENT");

  if (!comment) {
    dispatch(setAlert("The comment field cannot be empty", "error"));
    return;
  }


  console.log("addComment");

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ comment, assetId });

  try {

    const res = await axios.post("/api/comments/addcomment", body, config);

    dispatch({
      type: ADD_COMMENT,
      payload: res.data
    });

    dispatch(setAlert("Your comment has been added successfully", "success"));

  } catch (err) {
    dispatch(setAlert("Unable to add your comment. Please retry again.", "danger"));

    dispatch({
      type: ADD_COMMENT_ERROR
    });
  }
};


export const deleteComment = ({ id, assetId }) => async dispatch => {

  console.log("deleteComment " + id + assetId);

  if (!id || !assetId) {
    dispatch(setAlert("The selected comment cannot be deleted.", "danger"));
    return;
  }

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };


  try {

    const res = await axios.delete("/api/comments/" + assetId + "/" + id, config);

    dispatch({
      type: DELETE_COMMENT,
      payload: res.data
    });

    dispatch(setAlert("The selected comment has been deleted", "success"));

  } catch (err) {
    dispatch(setAlert("Unable to delete the selected comment. Please retry again.", "danger"));

    dispatch({
      type: DELETE_COMMENT_ERROR
    });
  }
};
