import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import CommentItem from "./CommentsItem";
import { getComments } from "../../actions/comment";
import { Link } from "react-router-dom";

const Comments = ({ getComments, comment: { comments, loading }, match }) => {
  useEffect(() => {
    getComments(match.params.mode);
  }, [getComments]);

  return loading ? (
    <Spinner />
  ) : (
      <Fragment>
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
        <br />
        {match.params.mode == "1" ? <h1 className="large text-primary">Unresolved Comments</h1> : <h1 className="large text-primary">Resolved Comment Threads</h1>}
        {comments && comments.length == 0 && (match.params.mode == "1" ? <p>Awesome! You dont have any Resolved Comment Threads!</p> : <p>Awesome! You dont have any Resolved Comment Threads!</p>)}
        <div className="posts">
          {comments.map(comment => (
            <CommentItem key={comment._id} comment={comment} mode={match.params.mode} />
          ))}
        </div>
      </Fragment>
    );
};

Comments.propTypes = {
  getComments: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired

};

const mapStateToProps = state => ({
  user: state.user,
  comment: state.comment
});

export default connect(mapStateToProps, { getComments })(Comments);
