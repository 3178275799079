import {
  GET_HEAD1S,
  GET_HEAD1,
  HEAD1_ERROR,
  DELETE_HEAD1,
  UPDATE_HEAD1
} from "../../actions/types";

const initialState = {
  head1: null,
  head1s: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HEAD1S:
      return {
        ...state,
        head1s: payload,
        loading: false
      };
    case GET_HEAD1:
    case UPDATE_HEAD1:
      return {
        ...state,
        head1: payload,
        loading: true // TODO: Check this once. true enables proper working of the edit-user page. HOW is this possible?
      };
    case DELETE_HEAD1:
      return {
        ...state,
        head1s: state.head1s.filter(head1 => head1._id !== payload),
        loading: false
      };
    case HEAD1_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
