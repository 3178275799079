import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deleteUser } from "../../actions/user";

const GroupItem = ({
  deleteUser,
  auth,
  group: { _id, group, userCount, allowedSubtopics },
  showActions,
}) => (
  <div className="useritem bg-white p-1 my-1">
    <div>
      <b className="">{group}</b>
      <p className="">{userCount} User(s)</p>
    </div>
    <div>
      {allowedSubtopics && allowedSubtopics.length > 0 ? (
        <div>
          <b>Allowed Subtopics</b>
          <p className="">
            {allowedSubtopics && allowedSubtopics.length > 0 ? (
              allowedSubtopics.map((allowedSubtopic) => (
                <li>{allowedSubtopic}</li>
              ))
            ) : (
              <p>This group has access to all the subtopics</p>
            )}
          </p>
        </div>
      ) : (
        <p>This group has access to all the subtopics</p>
      )}

      <br />
    </div>
    {showActions && (
      <Fragment>
        <p>
          <div
            onClick={() => {
              const win = window.open(`/edit-group/${group}`, "_blank");
              if (win != null) {
                win.focus();
              }
            }}
            className="btn btn-primary"
          >
            Modify Allowed SubtopicIDs
          </div>
        </p>
      </Fragment>
    )}
  </div>
);

GroupItem.defaultProps = {
  showActions: true,
};

GroupItem.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteUser })(GroupItem);
