import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import {
  GET_PRICINGPLANS,
  GET_PRICINGPLAN,
  PRICINGPLAN_ERROR,
  DELETE_PRICINGPLAN,
  DEPLOY_PRICINGPLANS
} from "./types";


// Get current user
export const getCurrentPricingPlan = id => async dispatch => {
  try {
    const res = await axios.get(`/api/pricingplans/pricingplan/${id}`);

    dispatch({
      type: GET_PRICINGPLAN,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PRICINGPLAN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });


  }
};

// Create or update user
export const createPricingPlan = (
  formData,
  history,
  edit = false,
  id = null
) => async dispatch => {
  try {

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      "/api/pricingplans/addpricingplan",
      { ...formData, id },
      config
    );

    dispatch({
      type: GET_PRICINGPLAN,
      payload: res.data
    });

    dispatch(
      setAlert(
        formData._id
          ? "The Pricing Plan details have been updated successfully"
          : "The Pricing Plan was created successfully",
        "success"
      )
    );

    history.push("/pricingplans");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch(
      setAlert(
        "The Pricing Plan couldn't be added!",
        "danger"
      )
    );

    dispatch({
      type: PRICINGPLAN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get posts
export const getPricingPlans = () => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/pricingplans");

    dispatch({
      type: GET_PRICINGPLANS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRICINGPLAN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deployPricingPlans = () => async dispatch => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {

    try {
      const res = await axios.get(BASE_SERVER_URL + "/api/pricingplans/deploy");

      if (res.data["msg"]) {
        dispatch(setAlert(res.data["msg"], "danger"));
      } else {
        dispatch(setAlert("The Pricing Plan has been pushed to PRODUCTION successfully", "success"));
      }

      dispatch({
        type: DEPLOY_PRICINGPLANS,
        payload: res.data
      });

    } catch (err) {
      console.log(err);

      dispatch(setAlert("Unable to push the Pricing Plan to PRODUCTION!", "danger"));

      dispatch({
        type: PRICINGPLAN_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};


// Delete post
export const deletePricingPlan = id => async dispatch => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await axios.delete(`/api/pricingplans/${id}`);
      dispatch({
        type: DELETE_PRICINGPLAN,
        payload: id
      });

      dispatch(setAlert("Pricing Plan has been removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: PRICINGPLAN_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
