import {
  GET_GLOBALNOTIFICATIONS,
  GET_GLOBALNOTIFICATION,
  GLOBALNOTIFICATION_ERROR,
  UPDATE_GLOBALNOTIFICATION,
  DELETE_GLOBALNOTIFICATION,
  ADD_GLOBALNOTIFICATION,
  DEPLOY_GLOBALNOTIFICATIONS,
} from "../actions/types";

const initialState = {
  globalnotification: null,
  globalnotifications: [],
  loading: true,
  deployPricingPlan: {},
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GLOBALNOTIFICATIONS:
      return {
        ...state,
        globalnotifications: payload,
        loading: false,
      };
    case GET_GLOBALNOTIFICATION:
    case UPDATE_GLOBALNOTIFICATION:
      return {
        ...state,
        globalnotification: payload,
        loading: true // TODO: Check this once. true enables proper working of the edit-pricingplan page. HOW is this possible?
      };
    case DELETE_GLOBALNOTIFICATION:
      return {
        ...state,
        globalnotifications: { ...state.globalnotifications, plans: state.globalnotifications.plans.filter(globalnotification => globalnotification._id !== payload) },
        loading: false
      };
    case GLOBALNOTIFICATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DEPLOY_GLOBALNOTIFICATIONS:
      return {
        ...state,
        deployPricingPlan: payload
      }
    default:
      return state;
  }
}
