import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Head1Item from "./Head1Item";
import { getHead1s } from "../../actions/content/head1";
import { Link } from "react-router-dom";

const Head1s = ({ getHead1s, head1: { head1s, loading } }) => {
  useEffect(() => {
    getHead1s();
  }, [getHead1s]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Topics</h1>
      <Link to="/create-head1" className="btn btn-primary">
        <i className="fas fa-user-plus"></i> Add a new one
      </Link>
      <Link className="btn btn-light my-1" to="/content-page">
        Go Back
      </Link>
      <div className="posts">
        {head1s.map(head1 => (
          <Head1Item key={head1._id} head1={head1} />
        ))}
      </div>
    </Fragment>
  );
};

Head1s.propTypes = {
  getHead1s: PropTypes.func.isRequired,
  head1: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  head1: state.head1
});

export default connect(mapStateToProps, { getHead1s })(Head1s);
