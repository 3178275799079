import axios from "axios";
import { BASE_SERVER_URL } from "../defaults";

import { setAlert } from "./alert";
import { SET_CONTENT, GET_CONTENT, CONTENT_ERROR } from "./types";

// Create or update user
export const setContent = (
  formData,
  history,
  edit = false
) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await axios.post(
      BASE_SERVER_URL + "/api/content",
      formData,
      config
    );

    dispatch({
      type: SET_CONTENT,
      payload: res.data
    });

    dispatch(
      setAlert(
        edit
          ? "The Content have been updated successfully"
          : "Content was created successfully",
        "success"
      )
    );

    history.push("/content-page");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: CONTENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get posts
export const getContent = () => async dispatch => {
  try {
    const res = await axios.get(BASE_SERVER_URL + "/api/content");

    dispatch({
      type: GET_CONTENT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CONTENT_ERROR,
      payload: {
        msg: err.response ? err.response.statusText : "Content Failure",
        status: err.response ? err.response.status : "500"
      }
    });
  }
};
