import {
  GET_HEAD2S,
  GET_HEAD2,
  HEAD2_ERROR,
  DELETE_HEAD2,
  UPDATE_HEAD2
} from "../../actions/types";

const initialState = {
  head2: null,
  head2s: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HEAD2S:
      return {
        ...state,
        head2s: payload,
        loading: false
      };
    case GET_HEAD2:
    case UPDATE_HEAD2:
      return {
        ...state,
        head2: payload,
        loading: true // TODO: Check this once. true enables proper working of the edit-user page. HOW is this possible?
      };
    case DELETE_HEAD2:
      return {
        ...state,
        head2s: state.head2s.filter(head2 => head2._id !== payload),
        loading: false
      };
    case HEAD2_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
