import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deleteAsset } from "../../actions/content/asset";

const AssetItem = ({
  deleteAsset,
  auth,

  asset: { _id, type, ref },
  showActions
}) => (
  <div className="post bg-white p-1 my-1">
    <h2>{type.toUpperCase()}</h2>

    <div>
      Reference Title <h3>{ref}</h3>
    </div>

    {showActions && (
      <Fragment>
        <div className="aParent">
          <p>
            <Link to={`/edit-asset/${_id}`} className="btn btn-primary">
              Edit Asset
            </Link>
          </p>
          <br />

          <p>
            <button
              onClick={() => deleteAsset(_id)}
              type="button"
              className="btn btn-danger"
            >
              Delete Asset
            </button>
          </p>
        </div>
      </Fragment>
    )}
  </div>
);

AssetItem.defaultProps = {
  showActions: true
};

AssetItem.propTypes = {
  asset: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteAsset })(AssetItem);
