import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import AssetItem from "./AssetItem";
import { getAssets } from "../../actions/content/asset";
import { Link } from "react-router-dom";

const Assets = ({ getAssets, asset: { assets, loading } }) => {
  useEffect(() => {
    getAssets();
  }, [getAssets]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Assets</h1>
      <Link to="/create-asset" className="btn btn-primary">
        <i className="fas fa-user-plus"></i> Add a new one
      </Link>
      <Link className="btn btn-light my-1" to="/content-page">
        Go Back
      </Link>
      <div className="posts">
        {assets.map(asset => (
          <AssetItem key={asset._id} asset={asset} />
        ))}
      </div>
    </Fragment>
  );
};

Assets.propTypes = {
  getAssets: PropTypes.func.isRequired,
  asset: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  asset: state.asset
});

export default connect(mapStateToProps, { getAssets })(Assets);
