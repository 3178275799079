import {
  GET_ASSETS,
  GET_ASSET,
  ASSET_ERROR,
  DELETE_ASSET,
  UPDATE_ASSET
} from "../../actions/types";

const initialState = {
  asset: null,
  assets: [],
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ASSETS:
      return {
        ...state,
        assets: payload,
        loading: false
      };
    case GET_ASSET:
    case UPDATE_ASSET:
      return {
        ...state,
        asset: payload,
        loading: true // TODO: Check this once. true enables proper working of the edit-user page. HOW is this possible?
      };
    case DELETE_ASSET:
      return {
        ...state,
        assets: state.assets.filter(asset => asset._id !== payload),
        loading: false
      };
    case ASSET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
