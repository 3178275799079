import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPricingPlan, getCurrentPricingPlan } from "../../actions/pricingplan";
import { setAlert } from "../../actions/alert";

const EditPricingPlan = ({
  pricingplan: { pricingplan, loading },
  createPricingPlan,
  getCurrentPricingPlan,
  history,
  match,
  setAlert
}) => {
  const [formData, setFormData] = useState({
    title: "", tagline: "", price: "", days: "", validityString: "", planType: "", planId: "",

    _id: null
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    console.log("useEffect");
    getCurrentPricingPlan(match.params.id);

    setFormData({
      title: !pricingplan ? "" : pricingplan.title,
      tagline: !pricingplan ? "" : pricingplan.tagline,
      price: !pricingplan ? "" : pricingplan.price,
      days: !pricingplan ? "" : pricingplan.days,
      validityString: !pricingplan ? "" : pricingplan.validityString,
      planType: !pricingplan ? "" : pricingplan.planType,
      planId: !pricingplan ? "" : pricingplan.planId,

      _id: !pricingplan ? null : pricingplan._id
    });

  }, [loading, getCurrentPricingPlan, match]);

  const { title, tagline, price, days, planType, validityString, planId, _id } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (!price.isNaN && price > 0) {
      if (!days.isNaN && days > 0) {
        createPricingPlan(formData, history);
      } else {
        setAlert("Duration is invalid! It should be a NUMBER more than 0.", "danger");
      }
    } else {
      setAlert("Price Field is invalid! It should be a NUMBER more than 0.", "danger");
    }
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit the Pricing Plan</h1>
      <p className="lead">
        <i className="fas fa-pricingplan" /> Let's get some information to edit the
        Pricing Plan
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Plan ID"
            name="planId"
            value={planId}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new Pricing Plan's ID (plan1, plan2, plan3, ..., plan7)</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Plan Ribbon Text"
            name="planType"
            value={planType}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new Pricing Plan's Ribbon Text (leave it Blank if no Ribbon is needed)</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter the new Pricing Plan's Title</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Tagline"
            name="tagline"
            value={tagline}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">Enter this Pricing Plan's - Tagline (example: ₹1000 for 2 years) </small>
        </div>

        <div className="form-group">
          <input
            style={{ display: "block", width: "100%", padding: "0.4rem", fontSize: "1.2rem", border: "1px solid #ccc" }}
            type="number"
            placeholder="Price in ₹"
            name="price"
            value={price}
            onChange={e => onChange(e)}
            isRequired
            min={0}
            oninput="validity.valid||(value='');"
          />
          <small className="form-text">Enter the Price of this Plan (in ₹ (Rupees)) </small>
        </div>
        <div className="form-group">
          <input
            style={{ display: "block", width: "100%", padding: "0.4rem", fontSize: "1.2rem", border: "1px solid #ccc" }}
            type="number"
            placeholder="Validity Duration in Days"
            name="days"
            value={days}
            onChange={e => onChange(e)}
            isRequired
            oninput="validity.valid||(value='');"
          />
          <small className="form-text">
            Enter the Validity Duration of this Plan (in terms of days; 1 year duration will be represented as 365 days)
          </small>
        </div>

        <div className="form-group">
          <input
            style={{ display: "block", width: "100%", padding: "0.4rem", fontSize: "1.2rem", border: "1px solid #ccc" }}
            type="text"
            placeholder="Validity String"
            name="validityString"
            value={validityString}
            onChange={e => onChange(e)}
            isRequired
          />
          <small className="form-text">
            Enter the Validity String to show in the Promotional Page (example: 2 Years)
          </small>
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/pricingplans">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditPricingPlan.propTypes = {
  createPricingPlan: PropTypes.func.isRequired,
  getCurrentPricingPlan: PropTypes.func.isRequired,
  pricingplan: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  pricingplan: state.pricingplan
});

export default connect(mapStateToProps, {
  setAlert,
  createPricingPlan,
  getCurrentPricingPlan
})(withRouter(EditPricingPlan));
