import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { markAsDone } from "../../actions/support";

const SupportItem = ({
  markAsDone,
  auth,
  support: { _id, name, feedback, email, date, served, type },
  showActions
}) => (
    <div className="useritem bg-white p-1 my-1">
      <div>
        <Link to={`#!`}>
          <h4>{name}</h4>

          {served ?
            <p className="lead">
              Done
          </p> :
            <p className="lead">
              Pending
            </p>
          }
        </Link>
      </div>
      <div>
        {type == 1 ? <p style={{ fontSize: "20px" }} >Feedback</p> : type == 2 ? <p style={{ fontSize: "20px" }} >New Content Request</p> : <p style={{ fontSize: "20px" }} >Testimony</p>}
        <p style={{ fontSize: "20px" }} >{feedback}</p>

        <p >{email}</p>
        <p className="post-date">
          <Moment fromNow date={date} />
          <br />
          {date}
        </p>
      </div>
      <Fragment>
        {served ? <Fragment></Fragment> :
          <Fragment>
            <p>
              <button
                onClick={() => {
                  markAsDone(_id);
                }}
                type="button"
                className="btn btn-success"
              >
                Mark as Done
            </button>

            </p>
            <br />

          </Fragment>
        }
        <p>
          <a href={"mailto:" + email} className="btn btn-primary">
            Send a Follow Up Mail
          </a>
        </p>
      </Fragment>
    </div>
  );

SupportItem.defaultProps = {
  showActions: true
};

SupportItem.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  markAsDone: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { markAsDone })(SupportItem);
