import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import {
  getAllDeployScheme,
  updateDevDeployScheme
} from "../../actions/deploy";

import { Link, withRouter, Redirect } from "react-router-dom";
import { deleteDeployDevItem, pushDevToProd } from "../../actions/deploy";
import { BASE_SERVER_URL } from "../../defaults";
import Alert from "../layout/Alert";

const Deploy = ({
  getAllDeployScheme,
  updateDevDeployScheme,
  deleteDeployDevItem,
  pushDevToProd,
  content,
  deploy: { deploy, loading },
  auth: { user }
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    getAllDeployScheme();
  }, [loading, getAllDeployScheme, deleteDeployDevItem, pushDevToProd]);

  function ShowProductionDeploySchema() {
    if (
      deploy &&
      deploy.currentProdSchema &&
      deploy.currentProdSchema.length > 0
    )
      return (
        <Fragment>
          <h1 className="large-nobottom text-primary">
            Production Deployment Schema
          </h1>
          <br />
          <div className="posts">
            {deploy.currentProdSchema.map((head1, index) => {
              return (
                <Fragment>
                  <p className="head1">{head1.title}</p>{" "}
                  {head1.children &&
                    head1.children.map((head2, index) => {
                      return (
                        <Fragment>
                          <div className="subtopic"> </div>{" "}
                          <p className="head2">{head2.title}</p>
                          {head2.children &&
                            head2.children.map((asset, index) => {
                              return (
                                <Fragment>
                                  {" "}
                                  <p className="head3">
                                    {"("}
                                    {asset.type}
                                    {")"} {asset.ref}
                                  </p>
                                </Fragment>
                              );
                            })}
                        </Fragment>
                      );
                    })}
                </Fragment>
              );
            })}
          </div>
        </Fragment>
      );
    else
      return (
        <Fragment>
          <h1 className="large text-primary">Production Deployment Schema</h1>
          <p className="lead-nobottom">
            No schema available in PRODUCTION! This needs your attention!
          </p>
        </Fragment>
      );
  }

  function ShowCurrentDeploySchema() {
    if (deploy && deploy.currentDevSchema && deploy.currentDevSchema.length > 0)
      return (
        <Fragment>
          <h1 className="large-nobottom text-primary">
            Development Deployment Schema
          </h1>
          <br />
          <Link to={`/add-deploy/head/head/head`} className="btn btn-deploy">
            Add Topic
          </Link>
          <div className="posts">
            {deploy.currentDevSchema.map((head1, index) => {
              return (
                <Fragment>
                  <p className="head1">{head1.title}</p>{" "}
                  <div className="subtopic">
                    <Link
                      to={`/add-deploy/head/${head1._id}/${0}`}
                      className="btn btn-deploy"
                    >
                      Add Subtopic
                    </Link>
                    <button
                      onClick={() => deleteDeployDevItem(head1._id)}
                      type="button"
                      className="btn btn-deploydanger"
                    >
                      Delete Above Topic
                    </button>
                  </div>
                  {head1.children &&
                    head1.children.map((head2, index) => {
                      return (
                        <Fragment>
                          <div className="subtopic"> </div>{" "}
                          <p className="head2">{head2.title}</p>
                          <div className="asset">
                            <Link
                              to={`/add-deploy/${head1._id}/${head2._id}/${index}`}
                              className="btn btn-deploy"
                            >
                              Add Asset
                            </Link>
                            <button
                              onClick={() => deleteDeployDevItem(head2._id)}
                              type="button"
                              className="btn btn-deploydanger"
                            >
                              Delete Above Subtopic
                            </button>
                          </div>
                          {head2.children &&
                            head2.children.map((asset, index) => {
                              return (
                                <Fragment>
                                  {" "}
                                  <p className="head3">
                                    {"("}
                                    {asset.type}
                                    {")"} {asset.ref}
                                  </p>
                                  <div className="asset">
                                    <Link
                                      to={`/add-deploy/${head1._id}/${
                                        head2._id
                                        }/${index + 1}`}
                                      className="btn btn-deploy"
                                    >
                                      Add Asset
                                    </Link>
                                    <button
                                      onClick={() =>
                                        deleteDeployDevItem(asset._id)
                                      }
                                      type="button"
                                      className="btn btn-deploydanger"
                                    >
                                      Delete Above Asset
                                    </button>
                                  </div>
                                </Fragment>
                              );
                            })}
                          <div className="subtopic">
                            <Link
                              to={`/add-deploy/head/${head1._id}/${index + 1}`}
                              className="btn btn-deploy"
                            >
                              Add Subtopic
                            </Link>
                          </div>
                        </Fragment>
                      );
                    })}
                  <p>
                    <Link
                      to={`/add-deploy/head/head/${index + 1}`}
                      className="btn btn-deploy"
                    >
                      Add Topic
                    </Link>
                  </p>
                </Fragment>
              );
            })}
          </div>
        </Fragment>
      );
    else
      return (
        <Fragment>
          <h1 className="large text-primary">Deployment</h1>
          <p className="lead-nobottom">
            No Deployment Schema found! Byts App will not work right now.
          </p>
          <p className="lead-nobottom">Create a Deployment Schema below.</p>
          <br />
          <h1 className="large-nobottom text-primary">
            Current Deployment Schema
          </h1>
          <br />
          <Link to={`/add-deploy/head/head/0`} className="btn btn-deploy">
            Add Topic
          </Link>
          <br />
        </Fragment>
      );
  }

  function ShowDeployActions() {
    if (user && user.priviledge == "Content Creator") {
      return (
        <Fragment>
          <h1 className="large text-primary">Deployment Controls</h1>
          <p className="lead-nobottom">
            You are not authorized to use the Deployment Controls.
          </p>
        </Fragment>
      );
    } else if (
      deploy &&
      deploy.currentDevSchema &&
      deploy.currentDevSchema.length > 0
    )
      return (
        <Fragment>
          <Alert />
          <h1 className="large text-primary">Deployment Controls</h1>
          <div className="subtopic">
            <button
              onClick={() => pushDevToProd()}
              type="button"
              className="btn btn-deploylarge"
            >
              Push Schema from Develepment to Production
            </button>
            <br />
            <br />
            <button
              onClick={() =>
                window.open(BASE_SERVER_URL + "/api/deploy/schema", "_blank")
              }
              type="button"
              className="btn btn-deploylarge"
            >
              Show me the PRODUCTION SCHEMA
            </button>
          </div>
        </Fragment>
      );
    else
      return (
        <Fragment>
          <h1 className="large text-primary">Deployment Controls</h1>
          <p className="lead-nobottom">
            No Development Deployment Schema found! Unable to show any
            Deployment Controls now!
          </p>
        </Fragment>
      );
  }

  return loading || !deploy || !deploy.currentDevSchema ? (
    <Spinner />
  ) : (
      <Fragment>
        <ShowCurrentDeploySchema />
        <br />
        <ShowProductionDeploySchema />
        <br />

        <ShowDeployActions />
        {/* 
      <div className="dash-buttons">
        <Link to="/head1-page" className="btn btn-primary">
          <i class="far fa-edit"></i> Manage Topics
        </Link>
        <Link to="/head2-page" className="btn btn-primary">
          <i class="far fa-edit"></i> Manage Subtopics
        </Link>
        <Link to="/asset-page" className="btn btn-primary">
          <i class="far fa-edit"></i> Manage Assets
        </Link>
      </div>
      <br />
      <ShowHead1s />
      <br />
      <ShowHead2s />
      <br />
      <ShowAssets />
      <br />

      */}
      </Fragment>
    );
};

Deploy.propTypes = {
  getAllDeployScheme: PropTypes.func.isRequired,
  updateDevDeployScheme: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  deploy: PropTypes.object.isRequired,
  deleteDeployDevItem: PropTypes.func.isRequired,
  pushDevToProd: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  content: state.content,
  deploy: state.deploy,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getAllDeployScheme,
  updateDevDeployScheme,
  deleteDeployDevItem,
  pushDevToProd
})(withRouter(Deploy));
