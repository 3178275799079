import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import GlobalNotificationItem from "./GlobalNotificationsItem";
import { getGlobalNotifications } from "../../actions/globalnotification";

import { Link } from "react-router-dom";

const GlobalNotifications = ({
  getGlobalNotifications,
  globalnotification: { globalnotifications, loading },
}) => {
  useEffect(() => {
    getGlobalNotifications();
  }, [getGlobalNotifications]);

  return loading && !globalnotifications ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Global Notifications</h1>

      <Link to="/create-globalnotification" className="btn btn-primary">
        Add a new Global Notification
      </Link>
      <div className="posts">
        {globalnotifications &&
        globalnotifications.notifs &&
        globalnotifications.notifs.length > 0 ? (
          globalnotifications.notifs.map((notif) => (
            <GlobalNotificationItem globalnotification={notif} />
          ))
        ) : (
          <h4 style={{ color: "#000000" }}>
            <br /> No Global Notification have been added yet!
          </h4>
        )}
      </div>
      <br />
    </Fragment>
  );
};

GlobalNotifications.propTypes = {
  getGlobalNotifications: PropTypes.func.isRequired,
  globalnotification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  globalnotification: state.globalnotification,
});

export default connect(mapStateToProps, { getGlobalNotifications })(
  GlobalNotifications
);
