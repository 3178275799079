import {
  GET_COMMENTS,
  GET_COMMENT,
  COMMENT_ERROR,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,

  GET_SHOWCOMMENTS,
  SHOWCOMMENT_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_ERROR
} from "../actions/types";

const initialState = {
  comment: null,
  comments: [],
  loading: true,
  error: {},
  errorShowComment: {},
  showComments: [],
  loadingShowComments: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COMMENTS:
      return {
        ...state,
        comments: payload,
        loading: false
      };

    case GET_SHOWCOMMENTS:
    case ADD_COMMENT:
    case DELETE_COMMENT:
    case UPDATE_COMMENT:

      return {
        ...state,
        showComments: payload,
        loadingShowComments: false
      };


    /*
  case DELETE_USER:
    return {
      ...state,
      users: state.users.filter(user => user._id !== payload),
      loading: false
    };
    */
    case DELETE_COMMENT_ERROR:
    case ADD_COMMENT_ERROR:
    case COMMENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };

    case SHOWCOMMENT_ERROR:
      return {
        ...state,
        errorShowComment: payload,
        loadingShowComments: false
      };
    default:
      return state;
  }
}
