import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { deleteGlobalNotification } from "../../actions/globalnotification";

const GlobalNotificationItem = ({
  deleteGlobalNotification,
  auth,
  identified,
  globalnotification: { _id, text, plans, at },
  showActions,
}) => (
  <div className="bg-white p-1 my-1">
    <div className="useritem">
      <div>
        <Link to={`#!`}>
          <p style={{ color: "#000000" }}>
            Notif ID: <b>{_id}</b>
          </p>
        </Link>
      </div>
      <div style={{ paddingLeft: "100px" }}>
        <h4>{text}</h4>

        <Moment fromNow date={at} />

        <p>{at}</p>
        <b>Plans</b>
        {plans && plans.length > 0 ? (
          <p>
            This notification will be send to users with the following plans
          </p>
        ) : (
          <Fragment></Fragment>
        )}
        {plans && plans.length > 0 ? (
          plans.map((plan) => <li>{plan}</li>)
        ) : (
          <p>This notification will be send to all users</p>
        )}
      </div>
    </div>
    <br />
    <Fragment>
      <button
        onClick={() => {
          deleteGlobalNotification(_id);
          window.location.reload(false);
        }}
        type="button"
        className="btn btn-danger"
      >
        Delete Global Notification
      </button>
    </Fragment>
  </div>
);

GlobalNotificationItem.defaultProps = {
  showActions: true,
};

GlobalNotificationItem.propTypes = {
  globalnotification: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  removeLike: PropTypes.func.isRequired,
  deleteGlobalNotification: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteGlobalNotification })(
  GlobalNotificationItem
);
